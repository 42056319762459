import Service from '../service'
import UserNormalizer from '../user/normalizer'

class Normalizer {
  static ContactPerson({ mobilePhone, lineAccount, memo, ...rest }) {
    return {
      ...UserNormalizer.UserItem(rest),
      mobilePhone,
      lineAccount,
      remark: memo,
    }
  }

  static RecordItem({ recordId, type, status, eventName, createdUser, displayTime, personal }) {
    return {
      // 收到時為 number，使用 string
      recordId: String(recordId),
      // 1: 新聞稿 / 2: 媒體活動
      type,
      status,
      eventName,
      createdUser: createdUser ? UserNormalizer.UserItem(createdUser) : null,
      // record detail 沒有這個欄位
      displayDateTime: displayTime,
      // record detail 沒有這個欄位
      isPersonal: personal,
    }
  }

  static RecordDetail({
    chief,
    estimateExecutionTime,
    estimateProvideTime,
    latestExecutionTime,
    style,
    contactPerson,
    eventPlace,
    guest,
    interviewEndTime,
    interviewStartTime,
    executionTime,
    executionMemo,
    memo,
    createdTime,
    ...rest
  }) {
    return {
      ...Normalizer.RecordItem(rest),
      chief: chief ? UserNormalizer.UserItem(chief) : null,
      estimateExecutionDateTime: estimateExecutionTime,
      estimateProvideDateTime: estimateProvideTime,
      latestExecutionDateTime: latestExecutionTime,
      styles: style,
      contactPersons: Service.normalizeList(contactPerson, Normalizer.ContactPerson),
      eventPlace,
      guest,
      interviewEndDateTime: interviewEndTime,
      interviewStartDateTime: interviewStartTime,
      executionDateTime: executionTime,
      undertakerRemark: executionMemo,
      applicantRemark: memo,
      createdDateTime: createdTime,
    }
  }

  static RecordList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.RecordItem)
  }
}

export default Normalizer
