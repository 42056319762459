import Bowser from 'bowser'
import Denormalizer from './denormalizer'
import Normalizer from './normalizer'

import AuthApi from '../auth'
import Service from '../service'

const parser = Bowser.getParser(window.navigator.userAgent)
const { browser, os, platform } = parser.getResult()

// http://13.112.26.77:5203/swagger/index.html
class User {
  static EMPLOYEE_ID = 'EMPLOYEE_ID'
  static DEPARTMENT_ID = 'DEPARTMENT_ID'
  static TEAM_ID = 'TEAM_ID'
  static AGENT_EMPLOYEE_ID = 'AGENT_EMPLOYEE_ID'
  static AGENT_DEPARTMENT_ID = 'AGENT_DEPARTMENT_ID'
  static AGENT_TEAM_ID = 'AGENT_TEAM_ID'
  static NOTIFICATION_SHOWED_UP = 'NOTIFICATION_SHOWED_UP'

  static setEmployeeId(employeeId) {
    localStorage.setItem(User.EMPLOYEE_ID, employeeId.trim())
  }

  static setDepartmentId(departmentId) {
    localStorage.setItem(User.DEPARTMENT_ID, departmentId?.trim())
  }

  static setTeamId(teamId) {
    localStorage.setItem(User.TEAM_ID, teamId?.trim())
  }

  static removeEmployee() {
    localStorage.removeItem(User.EMPLOYEE_ID)
    localStorage.removeItem(User.DEPARTMENT_ID)
    localStorage.removeItem(User.TEAM_ID)
  }

  static removeNotification() {
    localStorage.removeItem(User.NOTIFICATION_SHOWED_UP)
  }

  static getEmployeeId() {
    return localStorage.getItem(User.EMPLOYEE_ID)
  }

  static getDepartmentId() {
    return localStorage.getItem(User.DEPARTMENT_ID)
  }

  static getTeamId() {
    return localStorage.getItem(User.TEAM_ID)
  }

  static setAgentEmployeeId(employeeId) {
    return new Promise((resolve, reject) => localStorage.setItem(User.AGENT_EMPLOYEE_ID, employeeId.trim()))
  }

  static setAgentDepartmentId(departmentId) {
    localStorage.setItem(User.AGENT_DEPARTMENT_ID, departmentId?.trim())
  }

  static setAgentTeamId(teamId) {
    localStorage.setItem(User.AGENT_TEAM_ID, teamId?.trim())
  }

  static removeAgentEmployee() {
    localStorage.removeItem(User.AGENT_EMPLOYEE_ID)
    localStorage.removeItem(User.AGENT_DEPARTMENT_ID)
    localStorage.removeItem(User.AGENT_TEAM_ID)
  }

  static removeSessionStorage() {
    sessionStorage.clear()
  }

  static getAgentEmployeeId() {
    return localStorage.getItem(User.AGENT_EMPLOYEE_ID)
  }

  static getAgentDepartmentId() {
    return localStorage.getItem(User.AGENT_DEPARTMENT_ID)
  }

  static getAgentTeamId() {
    return localStorage.getItem(User.AGENT_TEAM_ID)
  }

  static isAgent() {
    return User.getAgentEmployeeId() !== null
  }

  static login({ account, password } = {}) {
    const service = new Service(
      {
        url: `/User/Login`,
        method: 'POST',
        data: {
          account,
          password,
          // '01' 服務平台(後台) / '02' 服務平台(前台) / '03' APP / '04' 影音資料庫(前台)
          platform: '02',
          deviceType: browser.name,
          version: browser.version,
          deviceId: `${platform.type}-${os.name}-${os.version}-${browser.name}-${browser.version}`,
        },
      },
      {
        withAccessToken: false,
        withTrim: false,
        denormalizer: Denormalizer.Login,
      },
    )

    return service.callApi().then(response => {
      AuthApi.setAccessToken(response.data.token)
      AuthApi.setCopyWritingSSOToken(response.data.ssoToken)
      User.setEmployeeId(account)

      return response
    })
  }

  // 登出不用 call API，清掉 Token 及重置 store (React Redux)
  static logout() {
    return new Promise((resolve, reject) => {
      AuthApi.removeAccessToken()
      AuthApi.removeCopyWritingSSOToken()
      User.removeEmployee()
      User.removeAgentEmployee()
      User.removeSessionStorage()
      User.removeNotification()
    })
  }

  static loginAgent({ applicant, current } = {}) {
    const service = new Service(
      {
        url: `/Agent/switch/team`,
        method: 'POST',
        data: {
          applicantDepartmentId: applicant.departmentId,
          applicantTeamId: applicant.teamId,
          applicantDepartmentName: applicant.departmentName,
          applicantTeamName: applicant.teamName,
          applicant: applicant.employeeId,
          currentDepartmentId: current.departmentId,
          currentTeamId: current.teamId,
          currentDepartmentName: current.departmentName,
          currentTeamName: current.teamName,
          // 1: 登入代理 / 2: 登出代理
          mode: 1,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.SwitchAgent,
      },
    )

    return service.callApi().then(response => {
      // 切換代理人的時候，只會更新 token 和代理人的 id, department id, team id
      AuthApi.setAccessToken(response.data.token)
      User.setAgentEmployeeId(applicant.employeeId)
      User.setAgentDepartmentId(applicant.departmentId)
      User.setAgentTeamId(applicant.teamId)

      return response
    })
  }

  static logoutAgent({ employeeId } = {}) {
    const service = new Service(
      {
        url: `/Agent/Switch`,
        method: 'POST',
        data: {
          employeeId,
          // 1: 登入代理 / 2: 登出代理
          mode: 2,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.SwitchAgent,
      },
    )

    return service.callApi().then(response => {
      // 切換代理人的時候，只會更新 token 和代理人的 id
      AuthApi.setAccessToken(response.data.token)
      User.removeAgentEmployee()

      return response
    })
  }

  static fetchJobDescriptionList() {
    const service = new Service(
      {
        url: `/JobDesc`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.JobDescriptionList,
      },
    )

    return service.callApi()
  }

  static fetchOrganizationList({ departmentId = '', teamId = '' } = {}) {
    const service = new Service(
      {
        url: `/Organization`,
        method: 'GET',
        data: {
          departmentId,
          teamId,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchOrganizationList,
        normalizer: Normalizer.OrganizationList,
      },
    )

    return service.callApi()
  }

  static fetchUserAgentList({
    withAll,
    employeeId,
    employeeName,
    jobDescription,
    departmentId,
    teamId,
    pagingIndex = 1,
    pagingSize = 10,
    displayAbroad,
  } = {}) {
    const service = new Service(
      {
        url: '/User/agent',
        method: 'GET',
        params: {
          withAll,
          employeeId,
          employeeName,
          jobDescription,
          departmentId,
          teamId,
          pagingIndex,
          pagingSize,
          displayAbroad, // 取得包含駐外單位的人員
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchUserAgentList,
        normalizer: Normalizer.UserList,
      },
    )

    return service.callApi()
  }

  static fetchUserList({
    withAll,
    employeeId,
    employeeName,
    jobDescription,
    departmentId,
    teamId,
    pagingIndex = 1,
    pagingSize = 10,
    displayAbroad,
  } = {}) {
    const service = new Service(
      {
        url: '/User',
        method: 'GET',
        params: {
          withAll,
          employeeId,
          employeeName,
          jobDescription,
          departmentId,
          teamId,
          pagingIndex,
          pagingSize,
          displayAbroad, // 取得包含駐外單位的人員
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchUserList,
        normalizer: Normalizer.UserList,
      },
    )

    return service.callApi()
  }

  static fetchUserDetail({ employeeId } = {}) {
    // [TAIT-4299] 當未輸入employeeId且目前不為代理身分時，使用token取得登入的使用者資訊；若為代理時，則改使用儲存的使用者ID
    // - `User/info`: 透過token取得資訊
    // - `User/${employeeId}`: 透過id取得資訊
    const service = new Service(
      {
        url: `/User/${employeeId ?? (this.isAgent() ? this.getEmployeeId() : 'info')}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.UserDetail,
      },
    )

    return service.callApi()
  }

  static addAgent({ startDateTime, endDateTime, applicantDeptTeamId, agentDeptTeamId } = {}) {
    const service = new Service(
      {
        url: `/Agent/team`,
        method: 'POST',
        data: {
          startDateTime,
          endDateTime,
          applicantDeptTeamId,
          agentDeptTeamId,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.AddAgent,
      },
    )

    return service.callApi()
  }

  static updateAgent({ recordId, startDateTime, endDateTime, applicantDeptTeamId, agentDeptTeamId } = {}) {
    const service = new Service(
      {
        url: `/Agent/team`,
        method: 'PUT',
        data: {
          recordId,
          startDateTime,
          endDateTime,
          applicantDeptTeamId,
          agentDeptTeamId,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.UpdateAgent,
      },
    )

    return service.callApi()
  }

  static removeAgent({ recordId } = {}) {
    const service = new Service(
      {
        url: `/Agent/${recordId}`,
        method: 'DELETE',
      },
      {
        withAccessToken: true,
      },
    )

    return service.callApi()
  }

  static fetchAgentRecordDetail({ recordId } = {}) {
    const service = new Service(
      {
        url: `/Agent/${recordId}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.AgentDetail,
      },
    )

    return service.callApi()
  }

  static fetchAgentRecordList({
    departmentId,
    sortBy,
    isRegistration,
    isApplication,
    startDateTime,
    endDateTime,
    pagingIndex,
    pagingSize = 10,
  } = {}) {
    const service = new Service(
      {
        url: `/Agent`,
        method: 'GET',
        params: {
          departmentId,
          sortBy,
          // 是否為登記桌: 如果是 true，會拿到「同部門所有人」的列表；如果是 false，會拿到「只有自己」的列表
          // 該值為 true 的時候，需帶 `departmentId`
          isRegistration,
          // 是否為申請人: 如果是 true，會拿到「{isRegistration}申請別人為代理人」的列表；如果是 false，會拿到「別人申請{isRegistration}為代理人」的列表
          isApplication,
          startDateTime,
          endDateTime,
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchAgentRecordList,
        normalizer: Normalizer.AgentRecordList,
      },
    )

    return service.callApi()
  }

  static fetchAgentRecordListByEmployee({
    employeeId,
    departmentId,
    teamId,
    pagingSize,
    pagingIndex,
    startTime,
    endTime,
    descending,
    isApplication,
    isRegistration,
  } = {}) {
    const service = new Service(
      {
        url: `/Agent/team`,
        method: 'GET',
        params: {
          employeeId,
          departmentId,
          teamId,
          pagingSize,
          pagingIndex,
          startTime,
          endTime,
          // 控管為由 "申請人" 或 "代理" 為出發點搜尋的控制閘 // true: 申請人, false: 代理
          isApplication,
          isRegistration,
          descending,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchAgentRecordListByEmployee,
        normalizer: Normalizer.AgentRecordList,
      },
    )

    return service.callApi()
  }

  static updateUserDefaultOrganization({ departmentId, teamId = '' } = {}) {
    const service = new Service(
      {
        url: `/User`,
        method: 'PATCH',
        data: {
          departmentId,
          teamId,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchOrganizationList,
      },
    )

    this.setDepartmentId(departmentId)
    this.setTeamId(teamId)
    return service.callApi()
  }

  static fetchUserNotificationSetting() {
    const service = new Service(
      {
        url: `/User/notification/setting`,
        method: 'GET',
      },
      {
        withAccessToken: true,
      },
    )

    return service.callApi()
  }

  static updateUserNotificationSettingForBrowser({ browserNotification } = {}) {
    const service = new Service(
      {
        url: `/User/notification/browser-setting`,
        method: 'PATCH',
        data: {
          notification: browserNotification,
        },
      },
      {
        withAccessToken: true,
      },
    )

    return service.callApi()
  }

  static updateUserNotificationSettingForEmail({ emailNotification } = {}) {
    const service = new Service(
      {
        url: `/User/notification/email-setting`,
        method: 'PATCH',
        data: {
          notification: emailNotification,
        },
      },
      {
        withAccessToken: true,
      },
    )

    return service.callApi()
  }
}

export default User
