class Denormalizer {
  static UpdateParticipantItem({ recordId, isAttend }) {
    return {
      // 使用 string，送出時為 number
      recordId: Number(recordId),
      attendFlag: isAttend,
    }
  }

  static CreateRecordItem({ roomId, startDateTime, endDateTime, topic, shouldResponse, participantIds }) {
    return {
      // 使用 string，送出時為 number
      roomId: Number(roomId),
      startTime: startDateTime,
      endTime: endDateTime,
      theme: topic,
      responseFlag: shouldResponse,
      participants: participantIds,
    }
  }

  static UpdateRecordItem({ recordId, roomId, startDateTime, endDateTime, topic, participantIds }) {
    return {
      // 使用 string，送出時為 number
      recordId: Number(recordId),
      // 使用 string，送出時為 number
      roomId: Number(roomId),
      startTime: startDateTime,
      endTime: endDateTime,
      theme: topic,
      participants: participantIds,
    }
  }

  static FetchRecordList({ startDateTime, endDateTime, roomIds }) {
    return {
      startTime: startDateTime,
      endTime: endDateTime,
      rooms: Array.isArray(roomIds) ? roomIds.join('|') : undefined,
    }
  }

  static FetchRoomList({ pagingIndex, pagingSize }) {
    return {
      pagingIndex,
      pagingSize,
    }
  }
}

export default Denormalizer
