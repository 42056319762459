const { localStorage } = window

class Auth {
  static ACCESS_TOKEN = 'ACCESS_TOKEN'
  static COPY_WRITING_SSO_TOKEN = 'COPY_WRITING_SSO_TOKEN'

  static setAccessToken(accessToken) {
    return new Promise((resolve, reject) => localStorage.setItem(Auth.ACCESS_TOKEN, accessToken))
  }

  static removeAccessToken() {
    return new Promise((resolve, reject) => localStorage.removeItem(Auth.ACCESS_TOKEN))
  }

  static getAccessToken() {
    return localStorage.getItem(Auth.ACCESS_TOKEN)
  }

  // 不使用 promise，因為該值會直接被使用
  static isAuthenticated() {
    const accessToken = Auth.getAccessToken()

    return typeof accessToken === 'string' && accessToken.length > 0
  }

  static setCopyWritingSSOToken(copyWritingSSOToken) {
    return new Promise((resolve, reject) => localStorage.setItem(Auth.COPY_WRITING_SSO_TOKEN, copyWritingSSOToken))
  }

  static removeCopyWritingSSOToken() {
    return new Promise((resolve, reject) => localStorage.removeItem(Auth.COPY_WRITING_SSO_TOKEN))
  }

  static getCopyWritingSSOToken() {
    return localStorage.getItem(Auth.COPY_WRITING_SSO_TOKEN)
  }
}

export default Auth
