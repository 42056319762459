import { combineReducers } from 'redux'
import { createReducer } from 'redux-create-reducer'
import * as actionTypes from './actionTypes'
import { addListToList } from '../reducer-operators'

// category
const initialStateCategory = {
  list: [],
}
const category = createReducer(initialStateCategory, {
  [actionTypes.CATEGORY_SET_LIST]: (state, { payload }) => ({
    ...state,
    list: payload,
  }),

  [actionTypes.CATEGORY_ADD_LIST_TO_LIST]: (state, { isBefore, payload }) => ({
    ...state,
    list: addListToList(state.list, payload, isBefore),
  }),
})

export default combineReducers({ category })
