import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

// Components
import Container from '../Container'

// Lib MISC
import { ToastContext } from '../../context'

// Variables / Functions
import { PLACEMENT } from '../../constants'

export const propTypes = {
  config: PropTypes.shape({
    duration: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    isClosable: PropTypes.bool,
    placementX: PropTypes.oneOf([PLACEMENT.LEFT, PLACEMENT.RIGHT, PLACEMENT.CENTER]),
    placementY: PropTypes.oneOf([PLACEMENT.TOP, PLACEMENT.BOTTOM, PLACEMENT.CENTER]),
    appendTarget: PropTypes.instanceOf(Element).isRequired,
  }),
  containerProps: PropTypes.object,
  children: PropTypes.any,
}

export const defaultProps = {
  config: {
    duration: 3000,
    isClosable: true,
    placementX: PLACEMENT.RIGHT,
    placementY: PLACEMENT.TOP,
    appendTarget: document.body,
  },
}

let timerIds = []
let lastKey = 0

function Provider(props) {
  const { config, containerProps = {}, children, ...restProps } = props
  const { duration: configDuration, placementX, placementY, isClosable, appendTarget } = config

  const [list, setList] = useState([])
  const listRef = useRef(list)
  listRef.current = list

  const addToast = options => {
    const duration = options.duration || configDuration
    const messageKey = lastKey + 1

    const item = { messageKey, placementX, isClosable, ...options }

    if (duration !== null) {
      const timerId = setTimeout(() => {
        clearTimeout(timerId)
        timerIds = timerIds.filter(id => id !== timerId)

        removeToast(messageKey)
      }, duration)

      timerIds = [...timerIds, timerId]
    }

    setList([...list, item])
    lastKey = messageKey

    return messageKey
  }

  const removeToast = messageKey => setList(listRef.current.filter(item => item.messageKey !== messageKey))
  const emptyToast = () => setList([])

  const context = { addToast, removeToast, emptyToast }

  return (
    <ToastContext.Provider value={context} {...restProps}>
      {ReactDOM.createPortal(<Container list={list} placementX={placementX} placementY={placementY} {...containerProps} />, appendTarget)}

      {children}
    </ToastContext.Provider>
  )
}

Provider.propTypes = propTypes
Provider.defaultProps = defaultProps

export default Provider
