import Service from '../service'
import UserNormalizer from '../user/normalizer'

class Normalizer {
  static RecordItem({ recordId, bookingType, startTime, endTime, cause, createdUser, goCar, returnCar, status, personal }) {
    return {
      // 收到時為 number，使用 string
      recordId: String(recordId),
      // S: 單程 / R: 去回
      bookingType,
      startDateTime: startTime,
      endDateTime: endTime,
      cause,
      createdUser: createdUser ? UserNormalizer.UserItem(createdUser) : null,
      outboundCarId: goCar,
      inboundCarId: returnCar,
      status,
      // record detail 沒有這個欄位
      isPersonal: personal,
    }
  }

  static RecordDetail({ assignMemo, bookingMemo, location, passenger, ...rest }) {
    return {
      ...Normalizer.RecordItem(rest),
      undertakerRemark: assignMemo,
      applicantRemark: bookingMemo,
      destination: location,
      passenger,
    }
  }

  static RecordList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.RecordItem)
  }

  static CarItem({ carId, carNumber, carCode, driver, driverPhone, bookingFlag }) {
    return {
      // 收到時為 number，使用 string
      carId: String(carId),
      carNumber,
      carCode,
      driver,
      driverPhone,
      isBookable: bookingFlag,
    }
  }

  static CarList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.CarItem)
  }

  static AddressItem({ addId, addName, address, createdTime, updatedTime }) {
    return {
      // 收到時為 number，使用 string
      addressId: String(addId),
      addressName: addName,
      address,
      createdDateTime: createdTime,
      updatedDateTime: updatedTime,
    }
  }

  static AddressList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.AddressItem)
  }
}

export default Normalizer
