import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/zh-tw'
import './polyfills'

import { initializeStore } from './js/lib/redux/store'
import { unregister } from './serviceWorker'
import App from './App'

import './styles/main.scss'

moment.locale('zh-tw')

const store = initializeStore()
const render = () => {
  // kukuDocs 的 DocxJS 卸載的時候會出現錯誤訊息：「DOMException: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.」，
  // 但是不知道在 DocxJS 在何時執行 removeChild 的動作，所以用以下 work around 的方式避免掉出錯（治標不治本）
  // REF: https://stackoverflow.com/a/54200382
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild
    Node.prototype.removeChild = function(child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this)
        }
        return child
      }
      return originalRemoveChild.apply(this, arguments)
    }

    const originalInsertBefore = Node.prototype.insertBefore
    Node.prototype.insertBefore = function(newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this)
        }
        return newNode
      }
      return originalInsertBefore.apply(this, arguments)
    }
  }

  console.log(
    `%c 目前版號 %c ${process.env.NPM_PACKAGE_VERSION} `,
    'background:rgb(043, 063, 087); padding: 1px; border-radius: 5px 0 0 5px; color: rgb(243, 245, 246); font-size: 20px;',
    'background:rgb(212, 225, 244); padding: 1px; border-radius: 0 5px 5px 0; color: rgb(043, 063, 087); font-size: 20px;',
  )

  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister()
render()
