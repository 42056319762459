// Separate the thunks from the action creators
// If the operation only dispatches a single action — forward the action creator function.
// If the operation uses a thunk, it can dispatch many actions and chain them with promises.
import * as actions from './actions'
import MeetingApi from '../../../api/meeting'

// record
export const setRecordList = actions.setRecordList
export const addListToRecordList = actions.addListToRecordList

export const fetchRecordList = ({ shouldReplace = true, startDateTime, endDateTime, roomIds }) => (dispatch, getStatus) => {
  return MeetingApi.fetchRecordList({ startDateTime, endDateTime, roomIds }).then(({ data }) => {
    const { list } = data

    dispatch(shouldReplace ? setRecordList({ list }) : addListToRecordList({ list }))

    return list
  })
}

// room
export const setRoomList = actions.setRoomList
export const addListToRoomList = actions.addListToRoomList

export const fetchRoomList = ({ shouldReplace = true, pagingIndex, pagingSize }) => (dispatch, getStatus) => {
  return MeetingApi.fetchRoomList({ pagingIndex, pagingSize }).then(({ data }) => {
    const { list } = data

    dispatch(shouldReplace ? actions.setRoomList({ list }) : actions.addListToRoomList({ list }))

    return list
  })
}

// query
export const setQueryValue = actions.setQueryValue

export const setQueryRoomIds = ({ roomIds }) => setQueryValue({ keyName: 'roomIds', value: roomIds })
