import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'

import AuthApi from './js/lib/api/auth'
import { Provider as ToastProvider, PLACEMENT, config } from './js/components/Toast'

const loading = () => null
const Auth = Loadable({ loader: () => import('./js/views/Auth'), loading })
const Home = Loadable({ loader: () => import('./js/views/Home'), loading })
const Print = Loadable({ loader: () => import('./js/views/Print'), loading })

function App(props) {
  const isAuthenticated = AuthApi.isAuthenticated()

  return isAuthenticated ? (
    <ToastProvider config={{ ...config, placementX: PLACEMENT.LEFT, placementY: PLACEMENT.BOTTOM }}>
      <Switch>
        <Route strict sensitive path='/home' component={Home} />
        <Route strict sensitive path='/print' component={Print} />
        <Redirect push from='/' to='/home' />
      </Switch>
    </ToastProvider>
  ) : (
    <Switch>
      <Route strict sensitive path='/auth' component={Auth} />
      <Redirect push from='/' to='/auth' />
    </Switch>
  )
}

export default withRouter(App)
