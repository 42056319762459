import { format } from '../../../utils/date-fns-addon'
import { BOOKING_TYPE, STATUS } from './constants'

export const mapCarToRecord = (record, cars) => ({
  ...record,
  outboundCar: cars.length === 0 ? {} : getCarById(cars, record.outboundCarId),
  inboundCar: cars.length === 0 ? {} : getCarById(cars, record.inboundCarId),
})

export const mapCarToRecords = (records, cars) => (cars.length === 0 ? [] : records.map(record => mapCarToRecord(record, cars)))

export const filterByStatus = (records, status) => records.filter(record => record.status === status)

export const filterByBookable = (records, isBookable) => records.filter(record => record.isBookable === isBookable)

export const getPeriodTextByBookingType = (startDateTime, endDateTime, bookingType) =>
  bookingType === BOOKING_TYPE.ONE_WAY
    ? format.dateTime(startDateTime)
    : format.period(startDateTime, endDateTime, { type: 'same-day', separator: '・' })

export const getBookingTypeText = bookingType => (bookingType === BOOKING_TYPE.ONE_WAY ? '單程' : bookingType === BOOKING_TYPE.ROUND_TRIP && '去回')

export const getCarById = (cars, carId) => {
  let car = { carId }

  switch (carId) {
    case 0:
      car.carCode = '無'
      break

    case -1:
      car.carCode = '取消用車'
      break

    case null:
      car.carCode = '未派車'
      break

    default:
      car = cars.find(car => car.carId === String(carId))
      break
  }

  return car
}

export const getDriverText = car => `${car.carCode} - ${car.driver}`

export const getStatusLabel = status => {
  const label = {}

  switch (status) {
    case STATUS.BOOKING:
      label.type = 'gray'
      label.content = '預約中'
      break

    case STATUS.BOOKED:
      label.type = 'primary'
      label.content = '已派車'
      break

    case STATUS.APPLICANT_CANCELLED:
    case STATUS.UNDERTAKER_CANCELLED:
      label.type = 'warn'
      label.content = '已取消'
      break
  }

  return label
}

export const getAddressWithName = address => `${address.addressName} - ${address.address}`
