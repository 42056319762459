export const MODULE_NAME = 'car'

export const BOOKING_TYPE = {
  // 單程
  ONE_WAY: 'S',
  // 去回
  ROUND_TRIP: 'R',
}

export const STATUS = {
  BOOKING: 1,
  BOOKED: 2,
  APPLICANT_CANCELLED: 3,
  UNDERTAKER_CANCELLED: 4,
}
