import { combineReducers } from 'redux'
import { createReducer } from 'redux-create-reducer'
import * as actionTypes from './actionTypes'
import { setItem, setItemValue } from '../reducer-operators'

// info
const initialStateInfo = null
const info = createReducer(initialStateInfo, {
  [actionTypes.INFO_SET_ITEM]: (state, { payload }) => setItem(payload),
})

// agent
const initialStateAgent = {
  list: [],
}
const agent = createReducer(initialStateAgent, {
  [actionTypes.AGENT_SET_ITEM_VALUE]: (state, { payload }) => setItemValue(state, payload.keyName, payload.value),
})

// organizationIndex
const initialStateOrganizationIndex = 0
const organizationIndex = createReducer(initialStateOrganizationIndex, {
  [actionTypes.ORGANIZATION_INDEX_SET_ITEM]: (state, { payload }) => setItem(payload),
})

export default combineReducers({ info, agent, organizationIndex })
