// Separate the thunks from the action creators
// If the operation only dispatches a single action — forward the action creator function.
// If the operation uses a thunk, it can dispatch many actions and chain them with promises.
import * as actions from './actions'
import UserApi from '../../../api/user'

// info
export const setInfo = actions.setInfo

export const fetchUserInfo = ({ employeeId } = {}) => (dispatch, getStatus) => {
  return UserApi.fetchUserDetail({ employeeId }).then(response => {
    dispatch(setInfo({ item: response.data }))

    return response
  })
}

// agent
export const setAgentValue = actions.setAgentValue

export const setAgentList = ({ list }) => setAgentValue({ keyName: 'list', value: list })

export const fetchAgentList = ({ departmentId, startDateTime, endDateTime, isApplication, pagingIndex, pagingSize }) => (dispatch, getStatus) => {
  return UserApi.fetchAgentRecordList({ departmentId, startDateTime, endDateTime, isApplication, pagingIndex, pagingSize }).then(response => {
    dispatch(setAgentList({ list: response.data.list }))

    return response
  })
}

// organizationIndex
export const setOrganizationIndex = actions.setOrganizationIndex

// reset store
export const clearStore = actions.clearStore

export const resetStore = () => (dispatch, getStatus) => {
  dispatch(clearStore())
}
