// Separate the thunks from the action creators
// If the operation only dispatches a single action — forward the action creator function.
// If the operation uses a thunk, it can dispatch many actions and chain them with promises.
import * as actions from './actions'
import SupplierApi from '../../../api/supplier'

// car
export const setCategoryList = actions.setCategoryList
export const addListToCategoryList = actions.addListToCategoryList

export const fetchCategoryList = ({ shouldReplace = true, pagingIndex, pagingSize }) => (dispatch, getStatus) => {
  return SupplierApi.fetchCategoryList({ pagingIndex, pagingSize }).then(({ data }) => {
    const { list } = data

    dispatch(shouldReplace ? actions.setCategoryList({ list }) : actions.addListToCategoryList({ list }))

    return list
  })
}
