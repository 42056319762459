import Denormalizer from './denormalizer'
import Normalizer from './normalizer'

import Service from '../service'
import exportFile from '../../utils/export-file'
import getFilename from '../../utils/get-filename'

// http://13.112.26.77:5208/swagger/index.html
class News {
  static exportRecord({ startDateTime, endDateTime } = {}) {
    const service = new Service(
      {
        url: `/News/Export`,
        method: 'GET',
        params: {
          startDateTime,
          endDateTime,
        },
        responseType: 'blob',
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.ExportRecord,
      },
    )

    return service
      .callApi()
      .then(({ request, headers }) => exportFile({ blob: request.response, fileName: getFilename(headers['content-disposition']) }))
  }

  static updateAssignment({ recordId, executionDateTime, remark } = {}) {
    const service = new Service(
      {
        url: `/News`,
        method: 'PATCH',
        data: {
          recordId,
          executionDateTime,
          remark,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.UpdateAssignment,
      },
    )

    return service.callApi()
  }

  static createRecordItem({
    type,
    eventName,
    estimateExecutionDateIime,
    isHoliday,
    latestExecutionDateTime,
    estimateProvideDateTime,
    styles,
    chief,
    interviewStartDateTime,
    interviewEndDateTime,
    eventPlace,
    guest,
    remark,
    contactPersons,
  } = {}) {
    const service = new Service(
      {
        url: `/News`,
        method: 'POST',
        data: {
          // 1: 新聞稿 / 2: 媒體活動
          type,
          eventName,
          estimateExecutionDateIime,
          isHoliday,
          latestExecutionDateTime,
          estimateProvideDateTime,
          // 1: 文字 / 2: 圖片 / 3: 影音
          styles,
          chief,
          interviewStartDateTime,
          interviewEndDateTime,
          eventPlace,
          guest,
          remark,
          contactPersons,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.CreateRecordItem,
      },
    )

    return service.callApi()
  }

  static deleteRecordItem({ recordId } = {}) {
    const service = new Service(
      {
        url: `/News/${recordId}`,
        method: 'DELETE',
      },
      {
        withAccessToken: true,
      },
    )

    return service.callApi()
  }

  static fetchRecordDetail({ recordId } = {}) {
    const service = new Service(
      {
        url: `/News/${recordId}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.RecordDetail,
      },
    )

    return service.callApi()
  }

  static fetchRecordList({ startDateTime, endDateTime, isUndertaker, isViewer } = {}) {
    const service = new Service(
      {
        url: '/News',
        method: 'GET',
        params: {
          startDateTime,
          endDateTime,
          isUndertaker,
          isViewer,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchRecordList,
        normalizer: Normalizer.RecordList,
      },
    )

    return service.callApi()
  }
}

export default News
