export const MODULE_NAME = 'supplier'

export const TYPE = {
  // 合約紀錄
  CONTRACT: 1,
  // 交易紀錄
  TRANSACTION: 2,
}

export const TYPE_TEXT_MAP = {
  [TYPE.CONTRACT]: '合約',
  [TYPE.TRANSACTION]: '交易',
}

export const CONTRACT_CATEGORY = {
  // 新約
  NEW: 1,
  // 續約
  RENEW: 2,
}

export const CONTRACT_CATEGORY_TEXT_MAP = {
  [CONTRACT_CATEGORY.NEW]: '新約',
  [CONTRACT_CATEGORY.RENEW]: '續約',
}

export const CONTRACT_TYPE = {
  // 支出
  EXPENSE: 1,
  // 收入
  INCOME: 2,
}

export const CONTRACT_TYPE_TEXT_MAP = {
  [CONTRACT_TYPE.EXPENSE]: '支出',
  [CONTRACT_TYPE.INCOME]: '收入',
}

export const PAYMENT_METHOD = {
  // 定期
  INSTALLMENT: 1,
  // 不定期
  PAY_OFF: 2,
}

export const PAYMENT_METHOD_TEXT_MAP = {
  [PAYMENT_METHOD.INSTALLMENT]: '定期',
  [PAYMENT_METHOD.PAY_OFF]: '不定期',
}
