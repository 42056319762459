import { combineReducers } from 'redux'
import { createReducer } from 'redux-create-reducer'
import * as actionTypes from './actionTypes'
import { setItem } from '../reducer-operators'

// 請採購作業
// 如果 sessionStorage 取不到值則給 0
const initialPurchaseBacklogCount = Number(window.sessionStorage.getItem('purchaseBacklogCount')) ?? 0

const purchaseBacklogCount = createReducer(initialPurchaseBacklogCount, {
  [actionTypes.SET_PURCHASE_BACKLOG_COUNT]: (state, { payload }) => setItem(payload),
})

// 行政庶務
// 如果 sessionStorage 取不到值則給 0
const initialAffairsBacklogCount = Number(window.sessionStorage.getItem('affairsBacklogCount')) ?? 0

const affairsBacklogCount = createReducer(initialAffairsBacklogCount, {
  [actionTypes.SET_AFFAIRS_BACKLOG_COUNT]: (state, { payload }) => setItem(payload),
})

// 請採購作業 - 交接案件
// 如果 sessionStorage 取不到值則給 0
const initialPurchaseBacklogTransferCount = Number(window.sessionStorage.getItem('purchaseBacklogTransferCount')) ?? 0

const purchaseBacklogTransferCount = createReducer(initialPurchaseBacklogTransferCount, {
  [actionTypes.SET_PURCHASE_BACKLOG_TRANSFER_COUNT]: (state, { payload }) => setItem(payload),
})

export default combineReducers({ purchaseBacklogCount, affairsBacklogCount, purchaseBacklogTransferCount })
