export const MODULE_NAME = 'meeting'

export const ROLE = {
  // 邀請人
  INVITER: 'INVITER',
  // 受邀人
  INVITEE: 'INVITEE',
  // 其他人
  OTHERS: 'OTHERS',
}
