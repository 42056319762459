import Service from '../service'

class Denormalizer {
  static ExportRecord({ startDateTime, endDateTime }) {
    return {
      startTime: startDateTime,
      endTime: endDateTime,
    }
  }

  static UpdateAssignment({ recordId, executionDateTime, remark }) {
    return {
      // 使用 string，送出時為 number
      recordId: Number(recordId),
      executionTime: executionDateTime,
      executionMemo: remark,
    }
  }

  static ContactPerson({ employeeId, mobilePhone, lineAccount, remark }) {
    return {
      empId: employeeId,
      mobilePhone,
      lineAccount,
      memo: remark,
    }
  }

  static CreateRecordItem({
    type,
    eventName,
    estimateExecutionDateIime,
    isHoliday,
    latestExecutionDateTime,
    estimateProvideDateTime,
    styles,
    chief,
    interviewStartDateTime,
    interviewEndDateTime,
    eventPlace,
    guest,
    remark,
    contactPersons,
  }) {
    return {
      type,
      eventName,
      estimateExecutionTime: estimateExecutionDateIime,
      holidayFlag: isHoliday,
      latestExecutionTime: latestExecutionDateTime,
      estimateProvideTime: estimateProvideDateTime,
      style: styles,
      chief,
      interviewStartTime: interviewStartDateTime,
      interviewEndTime: interviewEndDateTime,
      eventPlace,
      guest,
      memo: remark,
      contactPerson: Service.normalizeList(contactPersons, Denormalizer.ContactPerson),
    }
  }

  static FetchRecordList({ startDateTime, endDateTime, isUndertaker, isViewer }) {
    return {
      startTime: startDateTime,
      endTime: endDateTime,
      undertaker: isUndertaker,
      viewer: isViewer,
    }
  }
}

export default Denormalizer
