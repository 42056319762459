import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Components
import Typography from '../../../../components/Typography'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'

export const propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  callback: PropTypes.func,
}

function ServiceErrorModal(props) {
  const { message, callback, title } = props

  // 預設打開
  const [isOpened, setIsOpened] = useState(true)

  return (
    <Modal size='sm' isOpened={isOpened} isClosable={false}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <Typography.Text size='sm'>{message}</Typography.Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size='sm'
          type='primary'
          onClick={() => {
            setIsOpened(false)
            if (typeof callback === 'function') callback()
          }}
        >
          確定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
ServiceErrorModal.propTypes = propTypes

export default ServiceErrorModal
