import { combineReducers } from 'redux'
import { createReducer } from 'redux-create-reducer'
import * as actionTypes from './actionTypes'
import { addListToList, setItemValue } from '../reducer-operators'

// record
const initialStateRecord = {
  list: [],
}
const record = createReducer(initialStateRecord, {
  [actionTypes.RECORD_SET_LIST]: (state, { payload }) => ({
    ...state,
    list: payload,
  }),

  [actionTypes.RECORD_ADD_LIST_TO_LIST]: (state, { isBefore, payload }) => ({
    ...state,
    list: addListToList(state.list, payload, isBefore),
  }),
})

// filter
const initialStateFilter = {
  types: [],
}
const filter = createReducer(initialStateFilter, {
  [actionTypes.FILTER_SET_ITEM_VALUE]: (state, { payload }) => setItemValue(state, payload.keyName, payload.value),
})

export default combineReducers({ record, filter })
