import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import UserApi from '../api/user'

import * as modules from './modules'

const isDevelopment = process.env.NODE_ENV === 'development'

export const initializeStore = () => {
  let middlewares = [thunk]

  if (isDevelopment) {
    middlewares = [...middlewares, createLogger({ diff: true, collapsed: true, titleFormatter: action => `action： ${action.type}` })]
  }

  const initialState = {}

  const appReducer = combineReducers(modules)

  const rootReducer = (state, action) => {
    if (action.type === 'user/LOGOUT') {
      UserApi.logout()
      state = initialState // 重置 state (清空記憶體)
    }
    return appReducer(state, action)
  }

  const enhencer = composeWithDevTools(applyMiddleware(...middlewares))

  const store = createStore(rootReducer, initialState, enhencer)

  if (module.hot) {
    module.hot.accept('./modules', () => store.replaceReducer(modules))
  }

  return store
}
