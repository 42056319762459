import Service from '../service'
import UserNormalizer from '../user/normalizer'

class Normalizer {
  static SupplierDetail({ supplierId, name, taxId, category, contactName, contactPhone, address, memo }) {
    return {
      // 收到時為 number，使用 string
      supplierId: String(supplierId),
      supplierName: name,
      taxId,
      // 收到時為 number，使用 string
      categoryId: String(category),
      contactPerson: contactName,
      contactPhone,
      address,
      remark: memo,
    }
  }

  static SupplierItem({ updatedTime, updatedUser, ...rest }) {
    return {
      ...Normalizer.SupplierDetail(rest),
      updatedDateTime: updatedTime,
      updatedUser: updatedUser ? UserNormalizer.UserItem(updatedUser) : null,
    }
  }

  static SupplierList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.SupplierItem)
  }

  static CategoryItem({ categoryId, name, sort }) {
    return {
      // 收到時為 number，使用 string
      categoryId: String(categoryId),
      categoryName: name,
      sort,
    }
  }

  static CategoryList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.CategoryItem)
  }

  static InstallmentItem({ num, time, amount }) {
    return {
      phase: num,
      dateTime: time,
      amount,
    }
  }

  static Installment({ count, list }) {
    return {
      count,
      list: Service.normalizeList(list, Normalizer.InstallmentItem),
    }
  }

  static RecordDetail({
    purchaseId,
    recordNum,
    name,
    amount,
    requisitionUser,
    requisitionDeptId,
    requisitionDeptName,
    purchaseUser,
    systemFlag,
    transTime,
    startTime,
    endTime,
    notifyExpiration,
    category,
    transType,
    renewFlag,
    transMethod,
    installment,
    notifyPay,
    supplierName,
  }) {
    return {
      caseId: purchaseId,
      purchaseNumber: recordNum,
      caseName: name,
      amount,
      requisitionUser: requisitionUser ? UserNormalizer.UserItem(requisitionUser) : null,
      requisitionDepartmentId: requisitionDeptId,
      requisitionDepartmentName: requisitionDeptName,
      purchaseUser: purchaseUser ? UserNormalizer.UserItem(purchaseUser) : null,
      // 是否為系統轉檔
      isFromSystem: systemFlag,
      // 交易日期 (type = 2 only)
      transactionDateTime: transTime,
      // (type = 1 only)
      startDateTime: startTime,
      // (type = 1 only)
      endDateTime: endTime,
      // (type = 1 only)
      expirationNotify: notifyExpiration,
      // 合約種類 (type = 1 only) / 1: 新約 / 2: 續約
      contractCategory: category,
      // 款項類別 (type = 1 only) / 1: 支出 / 2: 收入
      contractType: transType,
      // 是否可續約 (type = 1 only)
      isRenewable: renewFlag,
      // 收款方式 (type = 1 only) / 1: 定期 / 2: 不定期
      paymentMethod: transMethod,
      // (type = 1 only)
      installment: installment === null ? installment : Normalizer.Installment(JSON.parse(installment)),
      // (type = 1 only)
      paymentNotify: notifyPay,
      supplierName,
    }
  }

  static RecordItem({ recordId, type, purchaseId, name, undertaker, updatedTime, updatedUser }) {
    return {
      recordId: String(recordId),
      type,
      caseId: purchaseId,
      caseName: name,
      undertaker: undertaker ? UserNormalizer.UserItem(undertaker) : null,
      updatedDateTime: updatedTime,
      updatedUser: updatedUser ? UserNormalizer.UserItem(updatedUser) : null,
    }
  }

  static RecordList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.RecordItem)
  }

  static AttachmentItem({ attachmentId, fileName, filePath, fileSize, createdUser, createdTime, updatedUser, updatedTime }) {
    return {
      attachmentId: String(attachmentId),
      name: fileName,
      size: fileSize,
      path: process.env.API_URL + filePath,
      createdUser: createdUser ? UserNormalizer.UserItem(createdUser) : null,
      createdDateTime: createdTime,
      updatedUser: updatedUser ? UserNormalizer.UserItem(updatedUser) : null,
      updatedDateTime: updatedTime,
    }
  }

  static AttachmentList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.AttachmentItem)
  }
}

export default Normalizer
