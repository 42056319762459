import React, { useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import Meta from './components/Meta'

// Lib MISC
import { ModalContext } from '../../context'
import isIE from '@/js/lib/utils/is-ie'

// Styles
import styles from './style.module.scss'
import Scrollbar from '../../../Scrollbar'

// Variables / Functions
const cx = classnames.bind(styles)

export const propTypes = {
  isHalf: PropTypes.bool,
  withMaxHeight: PropTypes.bool,
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around']),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  className: PropTypes.string,
  forwardRef: PropTypes.any,
  scrollProps: PropTypes.any,
}

export const defaultProps = {
  isHalf: false,
  withMaxHeight: true,
  padding: `24px 32px`,
}

function Body(props) {
  const { isHalf, withMaxHeight, align, padding, className, forwardRef, style, scrollProps = {}, ...restProps } = props

  const ref = useRef(forwardRef)
  const context = useContext(ModalContext)
  const { style: scrollStyle, ...restScrollProps } = scrollProps
  // 原本使用calc(100vh -modal其他地方高度)，但因ie11沒有全面支援calc，造成部分錯誤，因此改用js計算
  const totalHeight = context.paddingVertical + context.headerHeight + context.footerHeight
  let autoHeightMax = 0
  if (isIE()) {
    autoHeightMax = document.body.clientHeight - totalHeight
  } else {
    autoHeightMax = `calc(100vh - ${totalHeight}px)`
  }
  return (
    <Scrollbar
      autoHeight
      autoHeightMax={withMaxHeight ? autoHeightMax : 200}
      style={{ width: isHalf ? '50%' : '100%', ...scrollStyle }}
      innerProps={{ style: { height: 'calc(100% + 17px)' } }}
      {...restScrollProps}
    >
      <section
        style={{
          ...style,
          justifyContent: align,
          padding,
        }}
        className={cx('modal-body', className)}
        ref={ref}
        {...restProps}
      />
    </Scrollbar>
  )
}

Body.propTypes = propTypes
Body.defaultProps = defaultProps

const BodyWithRef = React.forwardRef((props, ref) => <Body {...props} forwardRef={ref} />)

BodyWithRef.Meta = Meta

export default BodyWithRef
