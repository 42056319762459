import { MODULE_NAME } from './constants'
import { mapCarToRecords } from './utils'

// record
export const getRecordList = (state, props) => state[MODULE_NAME].record.list

export const getRecordItemById = (state, props) => getRecordList(state, props).find(item => item.recordId === props.recordId)

// car
export const getCarList = (state, props) => state[MODULE_NAME].car.list

// query
export const getQueryValue = (state, props) => state[MODULE_NAME].query[props.keyName]

export const getQueryCarIds = (state, props) => getQueryValue(state, { keyName: 'carIds' })

// combo
export const getRecordListWithCar = (state, props) => mapCarToRecords(getRecordList(state, props), getCarList(state, props))
