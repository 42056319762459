function getFilename(disposition) {
  const regex = /filename\*=UTF-8''(.*)?\.(.*)/gi
  const matches = regex.exec(disposition)

  let filename = new Date().toISOString()

  if (matches !== null && matches[1] && matches[2]) {
    filename = `${decodeURIComponent(matches[1])}.${matches[2]}`
  }

  return filename
}

export default getFilename
