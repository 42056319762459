import Service from '../service'
import UserNormalizer from '../user/normalizer'

class Normalizer {
  static ParticipantItem({ participant, attendFlag }) {
    return {
      participant: UserNormalizer.UserDetail(participant),
      isAttend: attendFlag,
    }
  }

  static RecordItem({ recordId, roomId, startTime, endTime, theme, host, attendFlag, personal }) {
    return {
      // 收到時為 number，使用 string
      recordId: String(recordId),
      // 收到時為 number，使用 string
      roomId: String(roomId),
      startDateTime: startTime,
      endDateTime: endTime,
      topic: theme,
      host: UserNormalizer.UserDetail(host),
      // 是否出席，與自己無關的一率為 null
      isAttend: attendFlag,
      // 是否與自己有關
      isPersonal: personal,
    }
  }

  static RecordDetail({ responseFlag, participants, ...rest }) {
    return {
      ...Normalizer.RecordItem(rest),
      shouldResponse: responseFlag,
      participants: Service.normalizeList(participants, Normalizer.ParticipantItem),
    }
  }

  static RecordList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.RecordItem)
  }

  static RoomItem({ roomId, roomName, capacity, branchName, bookingFlag }) {
    return {
      // 收到時為 number，使用 string
      roomId: String(roomId),
      roomName,
      // 收到時為 number，使用 string
      capacity: String(capacity),
      departmentName: branchName,
      isBookable: bookingFlag,
    }
  }

  static RoomList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.RoomItem)
  }
}

export default Normalizer
