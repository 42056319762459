import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Spring, animated } from 'react-spring/renderprops'
import classnames from 'classnames/bind'

// Components
import Icon from '../../../Icon'

// Lib MISC
import { ToastContext } from '../../context'
import { PLACEMENT } from '../../constants'

// Style
import styles from './style.module.scss'

// Variables / Functions
const cx = classnames.bind(styles)
export const getTranslate3d = placement => {
  let translate3d = ''

  switch (placement) {
    case PLACEMENT.LEFT:
      translate3d = `translate3d(-50%, 0px, 0px)`
      break

    case PLACEMENT.RIGHT:
      translate3d = `translate3d(50%, 0px, 0px)`
      break
  }

  return translate3d
}

export const propTypes = {
  type: PropTypes.oneOf(['primary', 'danger']),
  placementX: PropTypes.oneOf([PLACEMENT.LEFT, PLACEMENT.RIGHT, PLACEMENT.CENTER]),
  duration: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  message: PropTypes.node,
  messageKey: PropTypes.any,
  isClosable: PropTypes.bool,
  onClose: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
}

export const defaultProps = {
  type: 'primary',
}

function Toast(props) {
  const { type, placementX, duration, message, messageKey, isClosable, onClose: propOnClose, style, className, ...restProps } = props

  const { removeToast } = useContext(ToastContext)

  const onClose = (event, messageKey) => {
    removeToast(messageKey)

    if (typeof propOnClose === 'function') {
      propOnClose(event, messageKey)
    }
  }

  return (
    <Spring from={{ opacity: 0, transform: getTranslate3d(placementX) }} to={{ opacity: 1, transform: `translate3d(0%, 0px, 0px)` }}>
      {springProps => (
        <animated.div className={cx('toast', className)} style={{ ...style, ...springProps }} data-type={type} {...restProps}>
          {message}

          {isClosable && (
            <button className={cx('toast__button-close')} onClick={event => (isClosable ? onClose(event, messageKey) : null)}>
              <Icon name='cross' mode='01' />
            </button>
          )}
        </animated.div>
      )}
    </Spring>
  )
}

Toast.propTypes = propTypes
Toast.defaultProps = defaultProps

export default Toast
