// Separate the thunks from the action creators
// If the operation only dispatches a single action — forward the action creator function.
// If the operation uses a thunk, it can dispatch many actions and chain them with promises.
import * as actions from './actions'
import NewsApi from '../../../api/news'

// record
export const setRecordList = actions.setRecordList
export const addListToRecordList = actions.addListToRecordList

export const fetchRecordList = ({ shouldReplace = true, startDateTime, endDateTime, isUndertaker, isViewer }) => (dispatch, getStatus) => {
  return NewsApi.fetchRecordList({ startDateTime, endDateTime, isUndertaker, isViewer }).then(({ data }) => {
    const { list } = data

    dispatch(shouldReplace ? setRecordList({ list }) : addListToRecordList({ list }))

    return list
  })
}

// filter
export const setFilterValue = actions.setFilterValue

export const setFilterTypes = ({ types }) => setFilterValue({ keyName: 'types', value: types })
