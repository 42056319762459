import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import Icon from '../Icon'

// Styles
import styles from './style.module.scss'

// Variables / Functions
const cx = classnames.bind(styles)
const crossData =
  'M6.42635725,5.00035726 L9.70435725,1.72135726 C9.95908763,1.46662689 10.0585712,1.09534929 9.96533338,0.747381133 C9.8720956,0.399412975 9.60030153,0.127618904 9.25233337,0.0343811208 C8.90436521,-0.0588566625 8.53308761,0.0406268793 8.27835725,0.295357258 L4.99935725,3.57435726 L1.72135725,0.295357258 C1.46662688,0.0406268856 1.09534928,-0.0588566514 0.747381128,0.0343811327 C0.399412974,0.127618917 0.127618906,0.399412984 0.0343811221,0.747381139 C-0.058856662,1.09534929 0.040626875,1.46662689 0.295357247,1.72135726 L3.57335725,5.00035726 L0.295357247,8.27935726 C-0.0984217797,8.67313628 -0.0984217797,9.31157823 0.295357247,9.70535726 C0.689136274,10.0991363 1.32757822,10.0991363 1.72135725,9.70535726 L5.00035725,6.42535726 L8.27935725,9.70435726 C8.53408761,9.95908763 8.90536521,10.0585712 9.25333337,9.96533338 C9.60130152,9.8720956 9.87309559,9.60030153 9.96633337,9.25233338 C10.0595712,8.90436522 9.96008762,8.53308762 9.70535725,8.27835726 L6.42635725,5.00035726 Z'
const arrowData =
  'M0.416403785,11.161609 L12.4921136,0.355681016 C13.022082,-0.118560339 13.8548896,-0.118560339 14.3470032,0.355681016 C14.8769716,0.829922371 14.8769716,1.57515879 14.3470032,2.01552576 L4.50473186,10.8228652 L34.6750789,10.8228652 C35.3943218,10.8228652 36,11.3648553 36,12.0084686 C36,12.6520819 35.3943218,13.194072 34.6750789,13.194072 L4.50473186,13.194072 L14.3470032,22.0014114 C14.8769716,22.4756528 14.8769716,23.2208892 14.3470032,23.6612562 C14.0820189,23.8983769 13.7413249,24 13.4006309,24 C13.0599369,24 12.7192429,23.8983769 12.4542587,23.6612562 L0.378548896,12.8553282 C0.113564669,12.6182075 0,12.3472124 0,12.0084686 C0,11.6697248 0.189274448,11.3987297 0.416403785,11.161609 Z'
const loadingData =
  'M17,1 L17,5 C17,5.552 16.5525,6 16,6 C15.4475,6 15,5.552 15,5 L15,1 C15,0.4475 15.4475,0 16,0 C16.5525,0 17,0.4475 17,1 Z M9.366,2.5095 L11.366,5.9735 C11.642,6.452 11.4785,7.0635 11,7.3395 C10.5215,7.6155 9.91,7.452 9.634,6.9735 L7.634,3.5095 C7.358,3.0315 7.5215,2.4195 8,2.1435 C8.4785,1.8675 9.09,2.031 9.366,2.5095 Z M6.9735,9.634 C7.452,9.91 7.616,10.5215 7.3395,11 C7.0635,11.478 6.452,11.642 5.9735,11.366 L2.5095,9.366 C2.031,9.09 1.8675,8.4785 2.1435,8 C2.4195,7.522 3.031,7.358 3.5095,7.634 L6.9735,9.634 Z M5,15 C5.5525,15 6,15.4475 6,16 C6,16.552 5.5525,17 5,17 L1,17 C0.4475,17 0,16.552 0,16 C0,15.4475 0.4475,15 1,15 L5,15 Z M16,26 C16.5525,26 17,26.4475 17,27 L17,31 C17,31.552 16.5525,32 16,32 C15.4475,32 15,31.552 15,31 L15,27 C15,26.4475 15.4475,26 16,26 Z M22.366,25.026 L24.366,28.49 C24.642,28.968 24.4785,29.58 24,29.856 C23.5215,30.132 22.91,29.9685 22.634,29.49 L20.634,26.026 C20.358,25.5475 20.5215,24.936 21,24.66 C21.4785,24.384 22.09,24.5475 22.366,25.026 Z M29.4905,22.634 C29.9685,22.91 30.1325,23.5215 29.8565,24 C29.5805,24.478 28.969,24.642 28.4905,24.366 L25.0265,22.366 C24.548,22.09 24.3845,21.4785 24.6605,21 C24.9365,20.522 25.548,20.358 26.0265,20.634 L29.4905,22.634 Z M31,15 C31.5525,15 32,15.4475 32,16 C32,16.552 31.5525,17 31,17 L27,17 C26.4475,17 26,16.552 26,16 C26,15.4475 26.4475,15 27,15 L31,15 Z M26.0265,11.366 C25.548,11.642 24.9365,11.4785 24.66,11 C24.384,10.5215 24.548,9.91 25.0265,9.634 L28.4905,7.634 C28.969,7.358 29.5805,7.5215 29.8565,8 C30.1325,8.478 29.969,9.09 29.4905,9.366 L26.0265,11.366 Z M24,2.1435 C24.4785,2.4195 24.642,3.0315 24.366,3.5095 L22.366,6.9735 C22.09,7.452 21.4785,7.6155 21,7.3395 C20.5215,7.0635 20.358,6.452 20.634,5.9735 L22.634,2.5095 C22.91,2.0315 23.5215,1.8675 24,2.1435 Z'

export const propTypes = {
  shouldShowCloseButton: PropTypes.bool.isRequired,
  shouldShowBackButton: PropTypes.bool.isRequired,
  shouldShowLoadingIcon: PropTypes.bool.isRequired,
  shouldShowLoadingOverlay: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  forwardRef: PropTypes.any,
}

function Content(props) {
  const {
    shouldShowCloseButton,
    shouldShowBackButton,
    shouldShowLoadingIcon,
    shouldShowLoadingOverlay,
    onClose,
    onBack,
    style,
    className,
    children,
    forwardRef,
    ...restProps
  } = props

  return (
    <div className={cx('modal-content', className)} style={style} {...restProps}>
      {shouldShowLoadingOverlay && <div className={cx('modal-content__loading-overlay')} />}
      {shouldShowCloseButton && !shouldShowBackButton && (
        <button className={cx('modal-content__icon-wrapper', 'modal-content__icon-wrapper--cross')} onClick={onClose}>
          <Icon data={crossData} size={10} />
        </button>
      )}
      {shouldShowBackButton && (
        <button className={cx('modal-content__icon-wrapper', 'modal-content__icon-wrapper--arrow')} onClick={onBack}>
          <Icon data={arrowData} width={36} height={24} />
        </button>
      )}
      {shouldShowLoadingIcon && (
        <span className={cx('modal-content__icon-wrapper', 'modal-content__icon-wrapper--loading')}>
          <Icon data={loadingData} size={32} />
        </span>
      )}
      {children}
    </div>
  )
}

Content.propTypes = propTypes

export default Content
