class Denormalizer {
  static UserItem({ employeeId, employeeName, jobDescription, departmentId, departmentName, extensionNumber, teamId, teamName }) {
    return {
      empId: employeeId,
      empName: employeeName,
      jobDesc: jobDescription,
      deptId: departmentId,
      deptName: departmentName,
      ext: extensionNumber,
      teamId,
      teamName,
    }
  }

  static Login({ account, password, platform, deviceType, version, deviceId }) {
    return {
      account: account.trim(),
      password: password,
      platform,
      deviceType,
      version,
      deviceId,
    }
  }

  static FetchUserAgentList({ withAll, employeeId, employeeName, jobDescription, departmentId, teamId, pagingIndex, pagingSize, displayAbroad }) {
    return {
      all: withAll,
      empId: employeeId,
      empName: employeeName,
      jobDesc: jobDescription,
      deptId: departmentId,
      teamId,
      pagingIndex,
      pagingSize,
      displayAbroad,
    }
  }

  static FetchUserList({ withAll, employeeId, employeeName, jobDescription, departmentId, teamId, pagingIndex, pagingSize, displayAbroad }) {
    return {
      all: withAll,
      empId: employeeId,
      empName: employeeName,
      jobDesc: jobDescription,
      deptId: departmentId,
      teamId,
      pagingIndex,
      pagingSize,
      displayAbroad,
    }
  }

  static AddAgent({ startDateTime, endDateTime, applicantDeptTeamId, agentDeptTeamId }) {
    return {
      startTime: startDateTime,
      endTime: endDateTime,
      applicantDeptTeamId: applicantDeptTeamId,
      agentDeptTeamId: agentDeptTeamId,
    }
  }

  static UpdateAgent({ recordId, startDateTime, endDateTime, applicantDeptTeamId, agentDeptTeamId }) {
    return {
      agentId: recordId,
      startTime: startDateTime,
      endTime: endDateTime,
      applicantDeptTeamId: applicantDeptTeamId,
      agentDeptTeamId: agentDeptTeamId,
    }
  }

  static SwitchAgent({
    applicant,
    applicantDepartmentId,
    applicantTeamId,
    applicantDepartmentName,
    applicantTeamName,
    currentDepartmentId,
    currentTeamId,
    currentDepartmentName,
    currentTeamName,
    mode,
  }) {
    return {
      applicant,
      applicantDeptId: applicantDepartmentId,
      applicantTeamId,
      applicantDeptName: applicantDepartmentName,
      applicantTeamName,
      currentDeptId: currentDepartmentId,
      currentTeamId,
      currentDeptName: currentDepartmentName,
      currentTeamName,
      mode,
    }
  }

  static FetchOrganizationList({ departmentId, teamId }) {
    return {
      deptId: departmentId,
      teamId,
    }
  }

  static FetchAgentRecordList({
    departmentId,
    departmentName,
    sortBy,
    isRegistration,
    isApplication,
    startDateTime,
    endDateTime,
    pagingIndex,
    pagingSize,
  }) {
    return {
      deptId: departmentId,
      deptName: departmentName,
      descending: sortBy === 'desc' ? true : sortBy === 'asc' && false,
      registration: isRegistration,
      application: isApplication,
      startTime: startDateTime,
      endTime: endDateTime,
      pagingIndex,
      pagingSize,
    }
  }

  static FetchAgentRecordListByEmployee({
    employeeId,
    departmentId,
    teamId,
    pagingSize,
    pagingIndex,
    startTime,
    endTime,
    isApplication,
    isRegistration,
  }) {
    return {
      empId: employeeId,
      deptId: departmentId,
      teamId,
      pagingSize,
      pagingIndex,
      startTime,
      endTime,
      application: isApplication,
      registration: isRegistration,
    }
  }
}

export default Denormalizer
