import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import flow from 'lodash/fp/flow'
import omitBy from 'lodash/fp/omitBy'
import mapValues from 'lodash/fp/mapValues'
import isUndefined from 'lodash/fp/isUndefined'
import isPlainObject from 'lodash/fp/isPlainObject'

// Components
import ServiceErrorModal from '../../views/Home/components/ServiceErrorModal'

import AuthApi from './auth'
import toCaseKeys, { CASES } from '../utils/to-case-keys'
import toPredicateValues from '../utils/to-predicate-values'

export const defaultNormalizer = response => response

class Service {
  constructor(
    config = {},
    { withAccessToken = false, denormalizer = defaultNormalizer, normalizer = defaultNormalizer, shouldHandleError = true, withTrim = true } = {},
  ) {
    this.config = config
    this.withAccessToken = withAccessToken
    this.denormalizer = denormalizer
    this.normalizer = normalizer
    this.withTrim = withTrim
    this.shouldHandleError = shouldHandleError // 因特殊案例，不受全局錯誤處理 Modal
  }

  static option = {
    toResponseCase: CASES.CAMEL,
    toRequestCase: CASES.SNAKE,
  }

  static apiConfig = {
    baseURL: process.env.API_URL,
  }

  static normalizeList(list, normalizer) {
    return [...(list || [])].map(item => normalizer(item))
  }

  static normalizePayloadWithPagination({ filterTotalCount, count, list, page, pagingIndex, pagingSize, requestDate }, normalizer) {
    return {
      filterTotalCount, // 請採購 > 待我審核 (一般案件 + 指定核銷承辦人) 的案件數加總 for Navigation 數字顯示
      count,
      list: Service.normalizeList(list, normalizer),
      page,
      pagingIndex,
      pagingSize,
      requestDateTime: requestDate,
    }
  }

  getAxiosInstance() {
    // https://stackoverflow.com/questions/45912500/reactjs-ie11-not-making-new-http-request-using-cached-data
    // https://stackoverflow.com/questions/45830531/axios-only-called-once-inside-self-invoking-function-internet-explorer/45835054#45835054
    // 關掉 IE 取資料會從 cache 拿的問題
    const IE_NO_CACHE_HEADERS = { Pragma: 'no-cache' }

    const apiConfig = this.withAccessToken
      ? Object.assign(Service.apiConfig, { headers: { Authorization: `Bearer ${AuthApi.getAccessToken()}` } })
      : Service.apiConfig

    apiConfig.headers = Object.assign(IE_NO_CACHE_HEADERS, apiConfig.headers)

    return axios.create(apiConfig)
  }

  getRequestConfig() {
    const { params, data, ...restConfig } = this.config

    const requestConfig = restConfig

    if (isPlainObject(params)) {
      requestConfig.params = this.handleParameter(params)
    }

    if (isPlainObject(data)) {
      requestConfig.data = this.handleParameter(data)
    }

    if (isPlainObject(requestConfig.headers) && requestConfig.headers['content-type'] === 'multipart/form-data') {
      const data = new FormData()

      Object.entries(requestConfig.data).forEach(([key, value]) =>
        Array.isArray(value) ? value.forEach(value => data.append(key, value)) : data.append(key, value),
      )
      requestConfig.data = data
    }

    return requestConfig
  }

  getErrorMessage(response) {
    const { status, data } = response

    const title = {
      401: '帳號或密碼錯誤',
      404: '帳號或密碼錯誤',
      500: '伺服器或網路錯誤',
    }

    const messages = this.withAccessToken
      ? {
          400: '參數錯誤。',
          401: '身份驗証已過期，請重新登入',
          403: '無管理者權限，請洽系統管理員。',
          404: '找不到該筆資料。',
          422: '時間重疊。',
          500: '請稍後再試。',
          424: data.message,
          // TAIT-2113 取得請購單號時顯示錯誤訊息 (call 取得請購單號 刪除請購單號 會有跟之前一樣的錯誤格式)
          // TAIT-2649 為了PASS外貿防火牆設定, 把5.x.x Error 換成 4.x.x Error

          409: data.message,
          // TAIT-4069 [併單]未跳出逕行報銷單申請畫面(請購單號仍顯示A單的情況下)，系統未阻擋清除請購單號
        }
      : {
          400: '參數錯誤。',
          401: '請確認後重新輸入。',
          403: '無管理者權限，無法登入，請洽系統管理員。',
          404: '請確認後重新輸入。',
          500: '請稍後再試。',
        }

    return { title: this.withAccessToken ? '系統錯誤' : title[status], message: messages[status] || '請洽系統管理員' }
  }

  debug(reason) {
    const { url, method } = this.config
    const { status, message, title } = reason
    console.warn(
      `[Debug Information] \n\n - apiUrl: ${url} \n - method: ${method} \n - status: ${status} \n - title: ${title}\n - message: ${message}\n`,
    )
  }

  createErrorElement() {
    const element = document.createElement('div')

    element.setAttribute('id', 'error')
    document.body.appendChild(element)
  }

  handleUnhandledError(status) {
    const removeElement = () => document.getElementById('error') && document.getElementById('error').remove()

    switch (status) {
      case 401: // 密碼錯誤
        return () => {
          removeElement()
          console.clear()
        }
      case 404:
        if (this.withAccessToken) {
          // 找不到該筆資料
          // 為保留錯誤發生前的畫面，產生 DOM 元素
          return () => {
            removeElement()
            window.history.back()
            console.clear()
          }
        } else {
          // 帳號密碼錯誤
          return () => {
            removeElement()
            console.clear()
          }
        }

      case 500: // 系統不明錯誤
        return () => {
          removeElement()
          window.history.replaceState(null, null, '/')
          window.location.reload()
          console.clear()
        }
      case 424: // 財務 API 取得預算資料錯誤
        return () => {
          removeElement()
          console.clear()
        }
      case 409: // 財務 API 取得預算資料錯誤
        return () => {
          removeElement()
          window.location.reload()
          console.clear()
        }
      case 999: // 自訂 Network error
        return () => removeElement()
      default:
        return null
    }
  }

  handleParameter(parameter) {
    const objectComparator = value => isPlainObject(value) || Array.isArray(value)
    const denormalizedParameter = this.denormalizer(parameter)
    const casedParameter = toCaseKeys(denormalizedParameter, Service.option.toRequestCase, { objectComparator })

    const predicator = object =>
      Array.isArray(object)
        ? object.filter(value => !isUndefined(value))
        : flow(
            omitBy(isUndefined),
            mapValues(value => (this.withTrim && typeof value === 'string' ? value.trim() : value)),
          )(object)
    const predicatedParameter = toPredicateValues(casedParameter, predicator, { objectComparator })

    return predicatedParameter
  }

  handleSuccess(response) {
    const casedData = toCaseKeys(response.data, Service.option.toResponseCase)
    const normalizedData = this.normalizer(casedData)

    const predicator = object => (Array.isArray(object) ? object.filter(value => !isUndefined(value)) : flow(omitBy(isUndefined))(object))
    const predicatedData = toPredicateValues(normalizedData, predicator)
    response.data = predicatedData

    return response
  }

  handleFailure(error) {
    let reason = null
    let message = ''
    let title = ''
    let status = 0

    if (!isUndefined(error.response)) {
      const { response } = error
      const errorMessageResponse = this.getErrorMessage(response)
      title = errorMessageResponse.title
      message = errorMessageResponse.message
      reason = Object.assign(error, Object.assign(response, { message, title }))
      ;({ status } = reason)
      this.debug(reason)
    } else {
      title = '網路連線異常'
      message = '請稍後再試。'
      reason = error
      status = 999
    }

    const callback = this.handleUnhandledError(status)
    if (status === 401 && AuthApi.isAuthenticated()) {
      // TAIT-3756 token 過期不用跳出錯誤 modal，直接導回登入頁面
      AuthApi.removeAccessToken()
      AuthApi.removeCopyWritingSSOToken()
      window.location.reload()
    } else if (this.shouldHandleError && callback) {
      this.createErrorElement()
      ReactDOM.render(<ServiceErrorModal title={title} message={message} callback={callback} />, document.getElementById('error'))
    }

    return Promise.reject(reason)
  }

  callApi() {
    const axiosInstance = this.getAxiosInstance()
    const requestConfig = this.getRequestConfig()

    return axiosInstance(requestConfig).then(
      response => this.handleSuccess(response),
      error => this.handleFailure(error),
    )
  }
}

export default Service
