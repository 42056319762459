import Service from '../service'

class Normalizer {
  static JobDescriptionItem(payload) {
    return payload
  }

  static JobDescriptionList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.JobDescriptionItem)
  }

  static OrganizationItem({ deptId, deptName, teamId, teamName }) {
    return {
      departmentId: deptId,
      departmentName: deptName,
      teamId,
      teamName,
    }
  }

  static OrganizationList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.OrganizationItem)
  }

  static EmployeeNameWithExtensionNumber({ employeeName, extensionNumber }) {
    return `${employeeName}${extensionNumber.length > 0 ? ` (${extensionNumber})` : ''}`
  }

  static UserItem({ empId, empName, ext, jobDesc, organization, deptId, deptName, teamId, teamName }) {
    const employeeName = empName
    // 如果分機不是純數字，代表可能有中文，而且已經加入 # 符號，使用原本的內容
    // 如果分機是純數字，就加上 # 符號
    // 分機可能是null須先排除
    const extensionNumber = ext && ext.match(/^\d+$/g) === null ? ext : `#${ext}`

    return {
      employeeId: empId,
      employeeName,
      jobDescription: jobDesc,
      extensionNumber,
      employeeNameWithExtensionNumber: Normalizer.EmployeeNameWithExtensionNumber({ employeeName, extensionNumber }),
      organizations: Service.normalizeList(organization, Normalizer.OrganizationItem),
      departmentId: deptId, // 「預算編列 - 會辦單位」判斷用，用比對預算編列的 departmentId
      departmentName: deptName, // 「預算編列 - 會辦單位」判斷用，用比對預算編列的 departmentId
      teamId,
      teamName,
    }
  }

  static UserList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.UserItem)
  }

  static UserDetail({ email, managers, mobilePhone, status, requestDate, lastTeamId, lastDeptId, ...rest }) {
    return {
      ...Normalizer.UserItem(rest),
      email,
      managers,
      mobilePhone,
      status,
      requestDate,
      lastTeamId,
      lastDepartmentId: lastDeptId,
    }
  }

  static AgentRecordItem({
    agentId,
    agent,
    applicant,
    createdTime,
    endTime,
    startTime,
    agentDeptId,
    agentDeptName,
    agentTeamId,
    agentTeamName,
    applicantDeptId,
    applicantDeptName,
    applicantTeamId,
    applicantTeamName,
  }) {
    return {
      recordId: agentId,
      agent: Normalizer.UserItem(agent),
      applicant: Normalizer.UserItem(applicant),
      createdDateTime: createdTime,
      endDateTime: endTime,
      startDateTime: startTime,
      agentId: agentId,
      agentDepartmentId: agentDeptId,
      agentDepartmentName: agentDeptName,
      agentTeamId,
      agentTeamName,
      applicantDepartmentId: applicantDeptId,
      applicantDepartmentName: applicantDeptName,
      applicantTeamId,
      applicantTeamName,
    }
  }

  static AgentDetail(payload) {
    return Normalizer.AgentRecordItem(payload)
  }

  static AgentRecordList(payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.AgentRecordItem)
  }
}

export default Normalizer
