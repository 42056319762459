import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@/js/components/Modal'
import Button from '@/js/components/Button'

export const propTypes = {
  isOpened: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  cancelable: PropTypes.bool,
  confimText: PropTypes.string,
}

const SimpleConfirmModal = ({ isOpened, title, message, onConfirm, onCancel, cancelable = true, confimText = '確認' }) => {
  return (
    <Modal isOpened={isOpened} onClose={cancelable ? onCancel : onConfirm}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button type='primary' size='sm' onClick={onConfirm}>
          {confimText}
        </Button>
        {cancelable && (
          <Button type='default' size='sm' onClick={onCancel}>
            取消
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

SimpleConfirmModal.propTypes = propTypes

export default SimpleConfirmModal
