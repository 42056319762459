import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Transition, animated } from 'react-spring/renderprops'
import classnames from 'classnames/bind'

// Componennts
import Overlay, { propTypes as OverlayPropTypes } from '../Overlay'
import Action from './components/Action'
import Body from './components/Body'
import Content, { propTypes as ContentPropTypes } from './components/Content'
import Footer from './components/Footer'
import Header from './components/Header'

// Feature
import SimpleConfirmModal from './feature/SimpleConfirmModal'

// Lib MISC
import { ModalContext } from './context'

// Style
import styles from './style.module.scss'

// Variables / Functions
const cx = classnames.bind(styles)
const parsePixelToNumeber = value => Number(value.replace(/px$/, ''))

export const propTypes = {
  contentProps: PropTypes.object,
  isOpened: PropTypes.bool.isRequired,
  isClosable: PropTypes.bool.isRequired,
  isBackale: PropTypes.bool,
  isLoading: PropTypes.bool,
  shouldShowOverlayOnLoading: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  beforeOpen: PropTypes.func,
  afterClose: PropTypes.func,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  appendTarget: OverlayPropTypes.appendTarget,
  size: ContentPropTypes.size,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  forwardRef: PropTypes.any,
}

export const defaultProps = {
  isOpened: false,
  isClosable: true,
  isBackale: false,
  isLoading: false,
  shouldShowOverlayOnLoading: true,
  shouldCloseOnOverlayClick: false,
  onClose: () => null,
  onBack: () => null,
  appendTarget: document.body,
  size: 'md',
}

const Modal = React.forwardRef((props, forwardRef) => {
  const {
    contentProps = {},
    isOpened,
    isClosable,
    isBackale,
    isLoading,
    shouldShowOverlayOnLoading,
    shouldCloseOnOverlayClick,
    beforeOpen,
    afterClose,
    onClose,
    onBack,
    appendTarget,
    size,
    width,
    style,
    className,
    children,
  } = props

  const onStart = (item, state) => !item && state === 'leave' && typeof beforeOpen === 'function' && beforeOpen()
  const onRest = (item, state) => !item && state === 'update' && typeof afterClose === 'function' && afterClose()

  const ref = useRef(forwardRef || null)
  const [paddingVertical, setPaddingVertical] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)

  useEffect(() => {
    if (ref.current !== null) {
      const style = getComputedStyle(ref.current)
      const paddingVertical = parsePixelToNumeber(style.paddingTop) + parsePixelToNumeber(style.paddingBottom)

      setPaddingVertical(paddingVertical)
    }
  }, [paddingVertical, headerHeight, footerHeight])

  const context = {
    paddingVertical,
    headerHeight,
    footerHeight,
    setHeaderHeight,
    setFooterHeight,
  }

  return (
    <Transition
      onStart={onStart}
      onRest={onRest}
      items={isOpened}
      from={{ opacity: 0, top: 40 }}
      enter={{ opacity: 1, top: 0 }}
      leave={{ opacity: 0, top: 40 }}
      config={{ tension: 500, friction: 36 }}
    >
      {isOpened =>
        isOpened &&
        (({ opacity, top }) =>
          ReactDOM.createPortal(
            <ModalContext.Provider value={context}>
              <animated.div ref={ref} className={cx('modal', className)} style={{ ...style, opacity }}>
                <Content
                  {...contentProps}
                  style={{ ...contentProps.style, top, width }}
                  data-size={size}
                  shouldShowCloseButton={!isLoading && isClosable}
                  shouldShowBackButton={!isLoading && isBackale}
                  shouldShowLoadingIcon={isLoading}
                  shouldShowLoadingOverlay={shouldShowOverlayOnLoading && isLoading}
                  onClose={onClose}
                  onBack={onBack}
                >
                  {children}
                </Content>
                <Overlay isShowed={isOpened} shouldCreatePortal={false} onClick={!isLoading && shouldCloseOnOverlayClick ? onClose : null} />
              </animated.div>
            </ModalContext.Provider>,
            appendTarget,
          ))
      }
    </Transition>
  )
})

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

function withModal(Component, config = {}) {
  function WithModalComponent({ isOpened: propIsOpened, onClose: propOnClose, ...props }) {
    const [isOpened, setIsOpened] = useState(propIsOpened)

    useEffect(() => {
      setIsOpened(propIsOpened)
    }, [propIsOpened])

    const onClose = (...params) => {
      setIsOpened(false)

      if (typeof propOnClose === 'function') {
        propOnClose(...params)
      }
    }
    const delegationProps = { isOpened, onClose, ...config, ...props }

    return (
      <Modal {...delegationProps}>
        <Component {...delegationProps} />
      </Modal>
    )
  }

  WithModalComponent.propTypes = {
    isOpened: PropTypes.bool,
    onClose: PropTypes.func,
  }

  return WithModalComponent
}

Modal.Action = Action
Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer

export { withModal, SimpleConfirmModal }
export default Object.assign(Modal, { withModal, SimpleConfirmModal })
