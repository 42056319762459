import { MODULE_NAME } from './constants'
import { mapRoomToRecords } from './utils'

// record
export const getRecordList = (state, props) => state[MODULE_NAME].record.list

export const getRecordItemById = (state, props) => getRecordList(state, props).find(item => item.recordId === props.recordId)

// room
export const getRoomList = (state, props) => state[MODULE_NAME].room.list

// query
export const getQueryValue = (state, props) => state[MODULE_NAME].query[props.keyName]

export const getQueryRoomIds = (state, props) => getQueryValue(state, { keyName: 'roomIds' })

// combo
export const getRecordListWithRoom = (state, props) => mapRoomToRecords(getRecordList(state, props), getRoomList(state, props))
