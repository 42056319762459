import { useContext } from 'react'
import Provider, { defaultProps } from './components/Provider'
import { ToastContext } from './context'

const useToast = () => {
  const { addToast, removeToast, emptyToast } = useContext(ToastContext)

  return { addToast, removeToast, emptyToast }
}

const config = defaultProps.config

export * from './constants'
export { Provider, useToast, config }
