import { MODULE_NAME } from './constants'
import UserApi from '../../../api/user'

// info
export const getInfo = (state, props) => state[MODULE_NAME].info

// agent
export const getAgentValue = (state, props) => state[MODULE_NAME].agent[props.keyName]

export const getAgentList = (state, props) => getAgentValue(state, { keyName: 'list' })

export const getAgentItemById = (state, props) => getAgentList(state, props).find(item => item.applicant.employeeId === props.employeeId) ?? null

// organizationIndex
export const getOrganizationIndex = (state, props) => state[MODULE_NAME].organizationIndex

// combo
export const getCurrentInfo = (state, props) => {
  if (UserApi.isAgent()) {
    const agentItem = getAgentItemById(state, { employeeId: UserApi.getAgentEmployeeId() })

    if (agentItem === null) {
      return null
    } else {
      return agentItem.applicant
    }
  } else {
    return getInfo(state, props)
  }
}

export const getCurrentOrganization = (state, props) => {
  const currentInfo = getCurrentInfo(state, props)
  const organizationIndex = getOrganizationIndex(state, props)

  if (currentInfo === null) return null
  if (currentInfo.organizations.length === 0) return null

  // 是代理人的情況時，取得代理人所屬組織 (跟登入帳號的同部門)
  if (UserApi.isAgent()) {
    const departmentId = UserApi.getAgentDepartmentId()
    const teamId = UserApi.getAgentTeamId()
    return currentInfo.organizations.find(x => x.departmentId === departmentId && x.teamId === teamId) ?? currentInfo.organizations[0]
  } else {
    return currentInfo.organizations[organizationIndex]
  }
}

export const getAgentListByCurrentOrganization = (state, props) => {
  const currentOrganization = getCurrentOrganization(state, props)

  if (currentOrganization === null) return []

  return getAgentList(state, props)
}
