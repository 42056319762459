import { ROLE } from './constants'

export const mapRoomToRecord = (record, rooms) => ({ ...record, room: rooms.length === 0 ? {} : rooms.find(room => room.roomId === record.roomId) })

export const mapRoomToRecords = (records, rooms) => (rooms.length === 0 ? [] : records.map(record => mapRoomToRecord(record, rooms)))

export const filterByAttend = (records, isAttend) => records.filter(record => record.isAttend === isAttend)

export const filterByPersonal = (records, isPersonal) => records.filter(record => record.isPersonal === isPersonal)

export const filterByBookable = (records, isBookable) => records.filter(record => record.isBookable === isBookable)

export const getRole = (record, userId) => {
  let role = ''

  switch (true) {
    case record.host.employeeId === userId:
      role = ROLE.INVITER
      break

    case record.participants.some(({ participant }) => participant.employeeId === userId):
      role = ROLE.INVITEE
      break

    default:
      role = ROLE.OTHERS
      break
  }

  return role
}

export const getResponseLabel = isAttend => {
  const label = {}

  switch (isAttend) {
    case null:
      label.type = 'gray'
      label.content = '未回覆'
      break

    case true:
      label.type = 'primary'
      label.content = '參加'
      break

    case false:
      label.type = 'warn'
      label.content = '不參加'
      break
  }

  return label
}
