import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
// import { autobind } from 'react-decoration'

// Components
import Toast, { propTypes as ToastPropTypes } from '../Toast'

// Style
import styles from './style.module.scss'

// Lib MISC
import { PLACEMENT } from '../../constants'

// Variables / Functions
const cx = classnames.bind(styles)

export const propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(ToastPropTypes)),
  placementX: PropTypes.oneOf([PLACEMENT.LEFT, PLACEMENT.RIGHT, PLACEMENT.CENTER]),
  placementY: PropTypes.oneOf([PLACEMENT.TOP, PLACEMENT.BOTTOM, PLACEMENT.CENTER]),
  className: PropTypes.string,
}

function Container(props) {
  const { list, placementX, placementY, className, ...restProps } = props

  return list.length > 0 ? (
    <div className={cx('toast-container', className)} data-placement-x={placementX} data-placement-y={placementY} {...restProps}>
      {list.map((item, index) => (
        <Toast key={index} {...item} />
      ))}
    </div>
  ) : null
}

Container.propTypes = propTypes

export default Container
