import { addDays } from 'date-fns'
import { TYPE, STATUS, STYLE, LATER_DAY } from './constants'

export const filterByTypes = (records, types) => records.filter(record => types.includes(record.type))

export const getTitleByType = type => (type === TYPE.NEWS ? '新聞稿預約 (台北時間)' : type === TYPE.MEDIA && '新聞稿及媒體活動預約 (台北時間)')

export const getMinDateByType = (date, type) => addDays(date, LATER_DAY[type])

export const getStyleName = style => {
  let name

  switch (style) {
    case STYLE.TEXT:
      name = '文字'
      break

    case STYLE.IMAGE:
      name = '圖片'
      break

    case STYLE.MEDIA:
      name = '影音'
      break
  }

  return name
}

export const getStatusLabel = status => {
  const label = {}

  switch (status) {
    case STATUS.BOOKING:
      label.type = 'gray'
      label.content = '預約中'
      break

    case STATUS.BOOKED:
      label.type = 'primary'
      label.content = '已處理'
      break

    case STATUS.APPLICANT_CANCELLED:
    case STATUS.UNDERTAKER_CANCELLED:
      label.type = 'warn'
      label.content = '已取消'
      break
  }

  return label
}
