export const MODULE_NAME = 'news'

export const TYPE = {
  // 新聞稿
  NEWS: 1,
  // 新聞稿及媒體活動
  MEDIA: 2,
}

export const STATUS = {
  BOOKING: 1,
  BOOKED: 2,
  APPLICANT_CANCELLED: 3,
  UNDERTAKER_CANCELLED: 4,
}

export const STYLE = {
  TEXT: 1,
  IMAGE: 2,
  MEDIA: 3,
}

export const LATER_DAY = {
  [TYPE.NEWS]: 2,
  [TYPE.MEDIA]: 5,
}
