import isIE from './is-ie'

function exportFile({ blob, fileName }) {
  // [TAIT-941]
  // https://stackoverflow.com/questions/24007073/open-links-made-by-createobjecturl-in-ie11
  if (isIE()) {
    window.navigator.msSaveOrOpenBlob(blob, fileName)
    return
  }

  const DownloadElement = document.createElement('a')
  const url = window.URL.createObjectURL(blob)

  DownloadElement.href = url
  DownloadElement.download = fileName

  document.body.appendChild(DownloadElement)
  DownloadElement.click()
  document.body.removeChild(DownloadElement)

  // [TAIT-665]
  // https://stackoverflow.com/questions/35686430/excel-export-in-javascript-using-blob-not-working-in-firefox
  setTimeout(() => window.URL.revokeObjectURL(url), 0)
}

export default exportFile
