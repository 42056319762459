import Denormalizer from './denormalizer'
import Normalizer from './normalizer'

import Service from '../service'

// http://13.112.26.77:5204/swagger/index.html
class Meeting {
  static updateParticipantItem({ recordId, isAttend } = {}) {
    const service = new Service(
      {
        url: `/Meeting/Participant`,
        method: 'PUT',
        data: {
          recordId,
          isAttend,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.UpdateParticipantItem,
      },
    )

    return service.callApi()
  }

  static createRecordItem({ roomId, startDateTime, endDateTime, topic, shouldResponse, participantIds } = {}) {
    const service = new Service(
      {
        url: `/Meeting`,
        method: 'POST',
        data: {
          roomId,
          startDateTime,
          endDateTime,
          topic,
          shouldResponse,
          participantIds,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.CreateRecordItem,
      },
    )

    return service.callApi()
  }

  static updateRecordItem({ recordId, roomId, startDateTime, endDateTime, topic, participantIds } = {}) {
    const service = new Service(
      {
        url: `/Meeting`,
        method: 'PUT',
        data: {
          recordId,
          roomId,
          startDateTime,
          endDateTime,
          topic,
          participantIds,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.UpdateRecordItem,
      },
    )

    return service.callApi()
  }

  static deleteRecordItem({ recordId } = {}) {
    const service = new Service(
      {
        url: `/Meeting/${recordId}`,
        method: 'DELETE',
      },
      {
        withAccessToken: true,
      },
    )

    return service.callApi()
  }

  static fetchRecordDetail({ recordId } = {}) {
    const service = new Service(
      {
        url: `/Meeting/${recordId}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.RecordDetail,
      },
    )

    return service.callApi()
  }

  static fetchRecordList({ startDateTime, endDateTime, roomIds } = {}) {
    const service = new Service(
      {
        url: '/Meeting',
        method: 'GET',
        params: {
          startDateTime,
          endDateTime,
          roomIds,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchRecordList,
        normalizer: Normalizer.RecordList,
      },
    )

    return service.callApi()
  }

  static fetchRoomDetail({ roomId } = {}) {
    const service = new Service(
      {
        url: `/Meeting/Room/${roomId}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.RoomItem,
      },
    )

    return service.callApi()
  }

  static fetchRoomList({ pagingIndex, pagingSize = 10 } = {}) {
    const service = new Service(
      {
        url: '/Meeting/Room',
        method: 'GET',
        params: {
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchRoomList,
        normalizer: Normalizer.RoomList,
      },
    )

    return service.callApi()
  }
}

export default Meeting
