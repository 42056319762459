import Denormalizer from './denormalizer'
import Normalizer from './normalizer'

import Service from '../service'
import exportFile from '../../utils/export-file'
import getFilename from '../../utils/get-filename'

// http://13.112.26.77:5205/swagger/index.html
class Car {
  static exportRecord({ startDateTime, endDateTime } = {}) {
    const service = new Service(
      {
        url: `/Car/Record/Export`,
        method: 'GET',
        params: {
          startDateTime,
          endDateTime,
        },
        responseType: 'blob',
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.ExportRecord,
      },
    )

    return service
      .callApi()
      .then(({ request, headers }) => exportFile({ blob: request.response, fileName: getFilename(headers['content-disposition']) }))
  }

  static updateAssignment({ recordId, outboundCarId, inboundCarId, remark } = {}) {
    const service = new Service(
      {
        url: `/Car/Record`,
        method: 'PATCH',
        data: {
          recordId,
          outboundCarId,
          inboundCarId,
          remark,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.UpdateAssignment,
      },
    )

    return service.callApi()
  }

  static createRecordItem({ bookingType, startDateTime, endDateTime, cause, destination, passenger, remark } = {}) {
    const service = new Service(
      {
        url: `/Car/Record`,
        method: 'POST',
        data: {
          // S: 單程 / R: 去回
          bookingType,
          startDateTime,
          endDateTime,
          cause,
          destination,
          passenger,
          remark,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.CreateRecordItem,
      },
    )

    return service.callApi()
  }

  static updateRecordItem({ recordId, bookingType, startDateTime, endDateTime, cause, destination, passenger, remark } = {}) {
    const service = new Service(
      {
        url: `/Car/Record`,
        method: 'PUT',
        data: {
          recordId,
          // S: 單程 / R: 去回
          bookingType,
          startDateTime,
          endDateTime,
          cause,
          destination,
          passenger,
          remark,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.UpdateRecordItem,
      },
    )

    return service.callApi()
  }

  static deleteRecordItem({ recordId } = {}) {
    const service = new Service(
      {
        url: `/Car/Record/${recordId}`,
        method: 'DELETE',
      },
      {
        withAccessToken: true,
      },
    )

    return service.callApi()
  }

  static fetchRecordDetail({ recordId } = {}) {
    const service = new Service(
      {
        url: `/Car/Record/${recordId}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.RecordDetail,
      },
    )

    return service.callApi()
  }

  static fetchRecordList({ startDateTime, endDateTime, carIds, isUndertaker } = {}) {
    const service = new Service(
      {
        url: '/Car/Record',
        method: 'GET',
        params: {
          startDateTime,
          endDateTime,
          carIds,
          isUndertaker,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchRecordList,
        normalizer: Normalizer.RecordList,
      },
    )

    return service.callApi()
  }

  static fetchCarList({ pagingIndex, pagingSize = 10 } = {}) {
    const service = new Service(
      {
        url: '/Car',
        method: 'GET',
        params: {
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchCarList,
        normalizer: Normalizer.CarList,
      },
    )

    return service.callApi()
  }

  static fetchAddressList({ pagingIndex, pagingSize = 10 } = {}) {
    const service = new Service(
      {
        url: '/Address',
        method: 'GET',
        params: {
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchAddressList,
        normalizer: Normalizer.AddressList,
      },
    )

    return service.callApi()
  }
}

export default Car
