// Separate the thunks from the action creators
// If the operation only dispatches a single action — forward the action creator function.
// If the operation uses a thunk, it can dispatch many actions and chain them with promises.
import * as actions from './actions'
import CarApi from '../../../api/car'

// record
export const setRecordList = actions.setRecordList
export const addListToRecordList = actions.addListToRecordList

export const fetchRecordList = ({ shouldReplace = true, startDateTime, endDateTime, carIds, isUndertaker }) => (dispatch, getStatus) => {
  return CarApi.fetchRecordList({ startDateTime, endDateTime, carIds, isUndertaker }).then(({ data }) => {
    const { list } = data

    dispatch(shouldReplace ? setRecordList({ list }) : addListToRecordList({ list }))

    return list
  })
}

// car
export const setCarList = actions.setCarList
export const addListToCarList = actions.addListToCarList

export const fetchCarList = ({ shouldReplace = true, withAll, pagingIndex, pagingSize }) => (dispatch, getStatus) => {
  return CarApi.fetchCarList({ withAll, pagingIndex, pagingSize }).then(({ data }) => {
    const { list } = data

    dispatch(shouldReplace ? actions.setCarList({ list }) : actions.addListToCarList({ list }))

    return list
  })
}

// query
export const setQueryValue = actions.setQueryValue

export const setQueryCarIds = ({ carIds }) => setQueryValue({ keyName: 'carIds', value: carIds })
