import Denormalizer from './denormalizer'
import Normalizer from './normalizer'

import Service from '../service'
import exportFile from '../../utils/export-file'
import getFilename from '../../utils/get-filename'

// http://13.112.26.77:5209/swagger/index.html
class Supplier {
  static createSupplierItem({ categoryId, taxId, supplierName, contactPerson, contactPhone, address, remark } = {}) {
    const service = new Service(
      {
        url: `/Supplier`,
        method: 'POST',
        data: {
          categoryId,
          taxId,
          supplierName,
          contactPerson,
          contactPhone,
          address,
          remark,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.CreateSupplierItem,
      },
    )

    return service.callApi()
  }

  static updateSupplierItem({ supplierId, categoryId, taxId, supplierName, contactPerson, contactPhone, address, remark }) {
    const service = new Service(
      {
        url: `/Supplier`,
        method: 'PUT',
        data: {
          supplierId,
          categoryId,
          taxId,
          supplierName,
          contactPerson,
          contactPhone,
          address,
          remark,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.UpdateSupplierItem,
      },
    )

    return service.callApi()
  }

  static fetchSupplierDetail({ supplierId } = {}) {
    const service = new Service(
      {
        url: `/Supplier/${supplierId}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.SupplierDetail,
      },
    )

    return service.callApi()
  }

  static fetchSupplierList({ name, keyword, taxId, categoryId, undertaker, pagingIndex, pagingSize = 10 } = {}) {
    const service = new Service(
      {
        url: `/Supplier`,
        method: 'GET',
        params: {
          name,
          keyword,
          taxId,
          undertaker,
          categoryId,
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchSupplierList,
        normalizer: Normalizer.SupplierList,
      },
    )

    return service.callApi()
  }

  static fetchCategoryList({ pagingIndex, pagingSize = 10 } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Category`,
        method: 'GET',
        params: {
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchCategoryList,
        normalizer: Normalizer.CategoryList,
      },
    )

    return service.callApi()
  }

  static exportRecord({ startDateTime, endDateTime } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Record/Export`,
        method: 'GET',
        params: {
          startDateTime,
          endDateTime,
        },
        responseType: 'blob',
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.ExportRecord,
      },
    )
    return service.callApi().then(({ request, headers }) => {
      exportFile({ blob: request.response, fileName: getFilename(headers['content-disposition']) })
    })
  }

  static validateRecord({ supplierId, caseId, recordNumber } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Record/Validate`,
        method: 'GET',
        params: {
          supplierId,
          caseId,
          recordNumber,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.ValidateRecord,
      },
    )

    return service.callApi()
  }

  static createRecordItem({
    type,
    supplierId,
    caseId,
    purchaseNumber,
    caseName,
    amount,
    requisitionEmployeeId,
    requisitionDepartmentId,
    purchaseEmployeeId,
    transactionDateTime,
    startDateTime,
    endDateTime,
    expirationNotify,
    contractCategory,
    contractType,
    isRenewable,
    paymentMethod,
    installment,
    paymentNotify,
    attachmentList,
  } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Record`,
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: {
          type,
          supplierId,
          caseId,
          purchaseNumber,
          caseName,
          amount,
          requisitionEmployeeId,
          requisitionDepartmentId,
          purchaseEmployeeId,
          transactionDateTime,
          startDateTime,
          endDateTime,
          expirationNotify,
          contractCategory,
          contractType,
          isRenewable,
          paymentMethod,
          installment,
          paymentNotify,
          attachmentList,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.CreateRecordItem,
      },
    )

    return service.callApi()
  }

  static updateRecordItem({
    recordId,
    type,
    supplierId,
    caseId,
    purchaseNumber,
    caseName,
    amount,
    requisitionEmployeeId,
    requisitionDepartmentId,
    purchaseEmployeeId,
    transactionDateTime,
    startDateTime,
    endDateTime,
    expirationNotify,
    contractCategory,
    contractType,
    isRenewable,
    paymentMethod,
    installment,
    paymentNotify,
  } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Record`,
        method: 'PUT',
        data: {
          recordId,
          type,
          supplierId,
          caseId,
          purchaseNumber,
          caseName,
          amount,
          requisitionEmployeeId,
          requisitionDepartmentId,
          purchaseEmployeeId,
          transactionDateTime,
          startDateTime,
          endDateTime,
          expirationNotify,
          contractCategory,
          contractType,
          isRenewable,
          paymentMethod,
          installment,
          paymentNotify,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.UpdateRecordItem,
      },
    )

    return service.callApi()
  }

  static fetchRecordDetail({ recordId } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Record/${recordId}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.RecordDetail,
      },
    )

    return service.callApi()
  }

  static fetchRecordList({ recordId, supplierId, pagingIndex, pagingSize = 10 } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Record`,
        method: 'GET',
        params: {
          recordId,
          supplierId,
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchRecordList,
        normalizer: Normalizer.RecordList,
      },
    )

    return service.callApi()
  }

  // 新增時用 createRecordItem 加入附件，編輯時才用這支 API 增加附件
  static createAttachmentItem({ recordId, file, setProgressCount = null } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Attachment`,
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgressCount && setProgressCount(percentCompleted)
        },
        data: {
          recordId,
          file,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.CreateAttachmentItem,
        shouldHandleError: false,
      },
    )

    return service.callApi()
  }

  // 新增時用 createRecordItem 加入附件，編輯時才用這支 API 增加附件
  static replaceAttachmentItem({ attachmentId, recordId, file, setProgressCount = null } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Attachment`,
        method: 'PUT',
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgressCount && setProgressCount(percentCompleted)
        },
        data: {
          attachmentId,
          recordId,
          file,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.ReplaceAttachmentItem,
        shouldHandleError: false,
      },
    )

    return service.callApi()
  }

  static deleteAttachmentItem({ attachmentId } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Attachment/${attachmentId}`,
        method: 'DELETE',
      },
      {
        withAccessToken: true,
      },
    )

    return service.callApi()
  }

  static fetchAttachmentList({ recordId } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Attachment`,
        method: 'GET',
        params: {
          recordId,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchAttachmentList,
        normalizer: Normalizer.AttachmentList,
      },
    )

    return service.callApi()
  }

  static downloadRecordAttachmentItem({ id, setProgressCount = null } = {}) {
    const service = new Service(
      {
        url: `/Supplier/Attachment/Download`,
        method: 'GET',
        onDownloadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgressCount && setProgressCount(percentCompleted)
        },
        params: {
          id,
        },
        responseType: 'blob',
      },
      {
        withAccessToken: true,
        // denormalizer: Denormalizer.FetchAttachmentList,
        // normalizer: Normalizer.AttachmentList,
      },
    )

    return service.callApi()
  }
}

export default Supplier
