import Service from '../service'

class Denormalizer {
  static CreateSupplierItem({ categoryId, taxId, supplierName, contactPerson, contactPhone, address, remark }) {
    return {
      // 使用 string，送出時為 number
      category: Number(categoryId),
      taxId,
      name: supplierName,
      contactName: contactPerson,
      contactPhone,
      address,
      memo: remark,
    }
  }

  static UpdateSupplierItem({ supplierId, categoryId, taxId, supplierName, contactPerson, contactPhone, address, remark }) {
    return {
      // 使用 string，送出時為 number
      supplierId: Number(supplierId),
      // 使用 string，送出時為 number
      category: Number(categoryId),
      taxId,
      name: supplierName,
      contactName: contactPerson,
      contactPhone,
      address,
      memo: remark,
    }
  }

  static FetchSupplierList({ name, keyword, taxId, categoryId, undertaker, pagingIndex, pagingSize } = {}) {
    return {
      name,
      keyword,
      taxId,
      undertaker,
      // 使用 string，送出時為 number
      category: typeof categoryId === 'undefined' ? undefined : Number(categoryId),
      pagingIndex,
      pagingSize,
    }
  }

  static FetchCategoryList({ pagingIndex, pagingSize } = {}) {
    return {
      pagingIndex,
      pagingSize,
    }
  }

  static InstallmentItem({ phase, dateTime, amount }) {
    return {
      num: phase,
      time: dateTime,
      amount,
    }
  }

  static Installment({ count, list }) {
    return {
      count,
      list: Service.normalizeList(list, Denormalizer.InstallmentItem),
    }
  }

  static CreateRecordItem({
    type,
    supplierId,
    caseId,
    purchaseNumber,
    caseName,
    amount,
    requisitionEmployeeId,
    requisitionDepartmentId,
    purchaseEmployeeId,
    transactionDateTime,
    startDateTime,
    endDateTime,
    expirationNotify,
    contractCategory,
    contractType,
    isRenewable,
    paymentMethod,
    installment,
    paymentNotify,
    attachmentList,
  }) {
    return {
      // 1: 合約 / 2: 交易
      type,
      // 使用 string，送出時為 number
      supplierId: Number(supplierId),
      purchaseId: caseId,
      purchaseNumber,
      name: caseName,
      amount,
      requisitionUser: requisitionEmployeeId,
      requisitionDeptId: requisitionDepartmentId,
      purchaseUser: purchaseEmployeeId,
      // 交易日期 (type = 2 only)
      transTime: transactionDateTime,
      // (type = 1 only)
      startTime: startDateTime,
      // (type = 1 only)
      endTime: endDateTime,
      // (type = 1 only)
      notifyExpiration: expirationNotify,
      // 合約種類 (type = 1 only) / 1: 新約 / 2: 續約
      category: contractCategory,
      // 款項類別 (type = 1 only) / 1: 支出 / 2: 收入
      transType: contractType,
      // 是否可續約 (type = 1 only)
      renewFlag: isRenewable,
      // 收款方式 (type = 1 only) / 1: 定期 / 2: 不定期
      transMethod: paymentMethod,
      // (type = 1 only)
      installment: typeof installment === 'undefined' ? undefined : JSON.stringify(Denormalizer.Installment(installment)),
      // (type = 1 only)
      notifyPay: paymentNotify,
      attachment: attachmentList,
    }
  }

  static UpdateRecordItem({
    recordId,
    type,
    supplierId,
    caseId,
    purchaseNumber,
    caseName,
    amount,
    requisitionEmployeeId,
    requisitionDepartmentId,
    purchaseEmployeeId,
    transactionDateTime,
    startDateTime,
    endDateTime,
    expirationNotify,
    contractCategory,
    contractType,
    isRenewable,
    paymentMethod,
    installment,
    paymentNotify,
  }) {
    return {
      // 使用 string，送出時為 number
      recordId: Number(recordId),
      // 1: 合約 / 2: 交易
      type,
      // 使用 string，送出時為 number
      supplierId: Number(supplierId),
      purchaseId: caseId,
      purchaseNumber,
      name: caseName,
      amount,
      requisitionUser: requisitionEmployeeId,
      requisitionDeptId: requisitionDepartmentId,
      purchaseUser: purchaseEmployeeId,
      // 交易日期 (type = 2 only)
      transTime: transactionDateTime,
      // (type = 1 only)
      startTime: startDateTime,
      // (type = 1 only)
      endTime: endDateTime,
      // (type = 1 only)
      notifyExpiration: expirationNotify,
      // 合約種類 (type = 1 only) / 1: 新約 / 2: 續約
      category: contractCategory,
      // 款項類別 (type = 1 only) / 1: 支出 / 2: 收入
      transType: contractType,
      // 是否可續約 (type = 1 only)
      renewFlag: isRenewable,
      // 收款方式 (type = 1 only) / 1: 定期 / 2: 不定期
      transMethod: paymentMethod,
      // (type = 1 only)
      installment: typeof installment === 'undefined' ? undefined : JSON.stringify(Denormalizer.Installment(installment)),
      // (type = 1 only)
      notifyPay: paymentNotify,
    }
  }

  static ExportRecord({ startDateTime, endDateTime }) {
    return {
      startTime: startDateTime,
      endTime: endDateTime,
    }
  }

  static ValidateRecord({ supplierId, caseId, recordNumber }) {
    return {
      // 使用 string，送出時為 number
      supplierId: Number(supplierId),
      purchaseId: caseId,
      recordNumber,
    }
  }

  static FetchRecordList({ recordId, supplierId, pagingIndex, pagingSize }) {
    return {
      recordId,
      // 使用 string，送出時為 number
      supplierId: Number(supplierId),
      pagingIndex,
      pagingSize,
    }
  }

  static CreateAttachmentItem({ recordId, file }) {
    return {
      // 使用 string，送出時為 number
      recordId: Number(recordId),
      file,
    }
  }

  static ReplaceAttachmentItem({ attachmentId, recordId, file }) {
    return {
      attachmentId,
      // 使用 string，送出時為 number
      recordId: Number(recordId),
      file,
    }
  }

  static FetchAttachmentList({ recordId }) {
    return {
      // 使用 string，送出時為 number
      recordId: Number(recordId),
    }
  }
}

export default Denormalizer
