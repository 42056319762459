export const setList = type => ({ list }) => ({
  type,
  payload: list,
})

export const addListToList = type => ({ isBefore = false, list }) => ({
  type,
  isBefore,
  payload: list,
})

export const clearList = type => () => ({
  type,
})

export const removeItemFromList = type => ({ keyName = 'id', key }) => ({
  type,
  payload: {
    keyName,
    key,
  },
})

export const setItem = type => ({ item }) => ({
  type,
  payload: item,
})

export const clearItem = type => () => ({
  type,
})

export const setItemValue = type => ({ keyName, value }) => ({
  type,
  payload: {
    keyName,
    value,
  },
})

export const clearData = type => () => ({
  type,
})
