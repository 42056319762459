import { MODULE_NAME } from './constants'
import { filterByTypes } from './utils'

// record
export const getRecordList = (state, props) => state[MODULE_NAME].record.list

export const getRecordItemById = (state, props) => getRecordList(state, props).find(item => item.recordId === props.recordId)

// filter
export const getFilterValue = (state, props) => state[MODULE_NAME].filter[props.keyName]

export const getFilterTypes = (state, props) => getFilterValue(state, { keyName: 'types' })

// combo
export const getRecordListFilterByTypes = (state, props) => filterByTypes(getRecordList(state, props), getFilterTypes(state, props))
