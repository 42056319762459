class Denormalizer {
  static ExportRecord({ startDateTime, endDateTime }) {
    return {
      startTime: startDateTime,
      endTime: endDateTime,
    }
  }

  static UpdateAssignment({ recordId, outboundCarId, inboundCarId, remark }) {
    return {
      // 使用 string，送出時為 number
      recordId: Number(recordId),
      assignGo: outboundCarId,
      assignReturn: inboundCarId,
      memo: remark,
    }
  }

  static CreateRecordItem({ bookingType, startDateTime, endDateTime, cause, destination, passenger, remark }) {
    return {
      // S: 單程 / R: 去回
      bookingType,
      startTime: startDateTime,
      endTime: endDateTime,
      cause,
      location: destination,
      passenger,
      memo: remark,
    }
  }

  static UpdateRecordItem({ recordId, bookingType, startDateTime, endDateTime, cause, destination, passenger, remark }) {
    return {
      // 使用 string，送出時為 number
      recordId: Number(recordId),
      // S: 單程 / R: 去回
      bookingType,
      startTime: startDateTime,
      endTime: endDateTime,
      cause,
      location: destination,
      passenger,
      memo: remark,
    }
  }

  static FetchRecordList({ startDateTime, endDateTime, carIds, isUndertaker }) {
    return {
      startTime: startDateTime,
      endTime: endDateTime,
      cars: Array.isArray(carIds) ? carIds.join('|') : undefined,
      undertaker: isUndertaker,
    }
  }

  static FetchCarList({ pagingIndex, pagingSize }) {
    return {
      pagingIndex,
      pagingSize,
    }
  }

  static FetchAddressList({ pagingIndex, pagingSize }) {
    return {
      pagingIndex,
      pagingSize,
    }
  }
}

export default Denormalizer
