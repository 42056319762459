import 'resize-observer-polyfill'
import 'react-app-polyfill/ie11'
import 'formdata-polyfill'
import 'core-js'
import * as Yup from 'yup'
import smoothscroll from 'smoothscroll-polyfill'

// 驗證陣列裡的物件的多個屬性的重複內容 (陣列中，兩個物件的 propertyNames 的每個值都相同，才會有錯誤)
Yup.addMethod(Yup.object, 'uniqueProperties', function(message, propertyNames) {
  return this.test('unique', message, function(object) {
    const isValid = object !== null && typeof object === 'object'
    const hasProperties = propertyNames.every(propertyName => object[propertyName])

    if (!isValid || !hasProperties) {
      return true
    }

    const siblings = this.parent.filter(child => child !== object)
    const isDuplicated = siblings.some(sibling => propertyNames.every(propertyName => sibling[propertyName] === object[propertyName]))

    if (isDuplicated) {
      throw this.createError({ path: this.path })
    }

    return true
  })
})

// polyfill CustomEvent for IE
// https://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work
;(function() {
  // for IE & Safari anchor smooth scroll
  smoothscroll.polyfill()
  if (typeof window.CustomEvent === 'function') return false

  function CustomEvent(event, params = { bubbles: false, cancelable: false, detail: undefined }) {
    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  // For anybody interested it seems to be possible to detect you are in IE (for this case)
  // by checking typeof(Event) which is 'function' for all browser except IE where it is 'object'.
  window.Event = CustomEvent
  window.CustomEvent = CustomEvent
})()
