// Separate the thunks from the action creators
// If the operation only dispatches a single action — forward the action creator function.
// If the operation uses a thunk, it can dispatch many actions and chain them with promises.
import * as actions from './actions'

// count
export const setPurchaseBacklogCount = actions.setPurchaseBacklogCount
export const setAffairsBacklogCount = actions.setAffairsBacklogCount
export const setPurchaseBacklogTransferCount = actions.setPurchaseBacklogTransferCount

export const setPurchaseBacklogCaseCount = count => setPurchaseBacklogCount(count)
export const setAffairsBacklogCaseCount = count => setAffairsBacklogCount(count)
export const setPurchaseBacklogTransferCaseCount = count => setPurchaseBacklogTransferCount(count)
